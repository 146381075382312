import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createDevis } from '../redux/actions/devisActions';
import { connect } from 'react-redux';

const Devis = (props) => {

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        nom: '',
        email: '',
        phone: '',
        service: '',
        message: '',
    });

    const [formloading, setFormLoading] = useState(false);
    const [formStatus, setFormStatus] = useState(null);
    const [alert, setAlert] = useState(null);
    const { loading } = props;

    const validateEmail = (email) => {
        // A simple email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // const validatePhoneNumber = (phone) => {
    //     // A simple phone number validation regex
    //     const phoneRegex = /^[0-9]{8}$/;
    //     console.log({ test: phoneRegex.test(phone) })
    //     return phoneRegex.test(phone);
    // };

    const validatePhoneNumber = (phone) => {
        // Regex to match the phone number format
        const phoneRegex = /^(?:\+|0)?226\d{8}$/;
        //console.log({ test: phoneRegex.test(phone) });
        return phoneRegex.test(phone);
    };

    // Test cases
    // console.log(validatePhoneNumber("+22670239010"));  // Logs: { test: true } and returns true
    // console.log(validatePhoneNumber("022670239010"));  // Logs: { test: true } and returns true
    // console.log(validatePhoneNumber("22670239010"));   // Logs: { test: true } and returns true
    // console.log(validatePhoneNumber("70239010"));      // Logs: { test: false } and returns false



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleSubmit = async (e) => {
        setFormLoading(true);
        e.preventDefault();
        if (!validateEmail(formData.email)) {
            setAlert({
                type: 'error',
                message: 'Email Invalide !',
            });
            setFormLoading(false);
            return;
        } else closeAlert();

        if (!validatePhoneNumber(formData.phone)) {
            //console.log({ number: formData.phone })
            setAlert({
                type: 'error',
                message: 'Numéro de téléphone Invalide !',
            });
            setFormLoading(false);
            return;
        } else closeAlert();

        if (formData.service === '') {
            setAlert({
                type: 'error',
                message: 'Veuillez sélectionner un service.',
            });
            setFormLoading(false);
            return;
        } else closeAlert();

        // Validate the textarea
        if (formData.message.trim() === '') {
            setAlert({
                type: 'error',
                message: 'Veuillez saisir un message.',
            });
            setFormLoading(false);
            return;
        } else closeAlert();

        let data = { "data": formData }
        const response = await dispatch(createDevis(data));
        //console.log({ response })
        setAlert({
            type: response ? 'success' : 'error',
            message: response ? 'Votre demande a bien été envoyée !' : 'Une Erreur s\'est produite veuillez réessayer !',
        });

        // Reset the form data after the API call (optional)
        setFormData({
            nom: '',
            email: '',
            phone: '',
            service: '',
            message: '',
        });
        setFormLoading(false);
    };
    const closeAlert = () => {
        setAlert(null);
    };

    return (
        <>
            {alert && (
                <div className={`alert text-center my-3 ${alert.type === 'success' ? 'alert-success' : 'alert-danger'}`} role="alert">
                    {alert.message}
                    <button type="button" className="btn-close" aria-label="Close" onClick={closeAlert}></button>
                </div>
            )}

            <form onSubmit={handleSubmit} className="quote-form ajax-contact">
                <div className="row">
                    <div className="form-group col-md-6">
                        <input
                            type="text"
                            required
                            className="form-control"
                            name="nom"
                            id="nom"
                            placeholder="Votre nom complet"
                            value={formData.nom}
                            onChange={handleInputChange}
                        />
                        <i className="fal fa-user" />
                    </div>
                    <div className="form-group col-md-6">
                        <input
                            type="email"
                            required
                            className="form-control"
                            name="email" id="email"
                            placeholder="Votre Email"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                        <i className="fal fa-envelope" />
                    </div>
                    <div className="form-group col-md-6" style={{ display: "flex" }}>
                        <div className="form-group col-md-4">
                            <select
                                name="indicatif"
                                required
                                id="service"
                                className="form-select"
                                value={formData.indicatif}
                                onChange={handleInputChange}
                            >
                                <option value disabled="disabled" selected="selected">Sélectionnez un indicatif</option>
                                <option value="+229">Bénin (+229)</option>
                                <option value="+226">Burkina Faso (+226)</option>
                                <option value="+225">Côte d'Ivoire (+225)</option>
                                <option value="+220">Gambie (+220)</option>
                                <option value="+233">Ghana (+233)</option>
                                <option value="+224">Guinée (+224)</option>
                                <option value="+245">Guinée-Bissau (+245)</option>
                                <option value="+231">Libéria (+231)</option>
                                <option value="+223">Mali (+223)</option>
                                <option value="+227">Niger (+227)</option>
                                <option value="+234">Nigeria (+234)</option>
                                <option value="+221">Sénégal (+221)</option>
                                <option value="+232">Sierra Leone (+232)</option>
                                <option value="+228">Togo (+228)</option>
                            </select>
                        </div>
                        <div className="form-group col-md-8">
                            <input
                                type="number"
                                required
                                className="form-control"
                                name="phone"
                                id="number"
                                placeholder="Votre numéro de téléphone"
                                value={formData.phone}
                                onChange={handleInputChange}
                            />
                            <i className="fal fa-phone" />
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <select
                            name="service"
                            required
                            id="service"
                            className="form-select"
                            value={formData.service}
                            onChange={handleInputChange}
                        >
                            <option value disabled="disabled" selected="selected" hidden>Sélectionnez un service
                            </option>
                            <option value="Mise en relation emploi">Mise en relation emploi</option>
                            <option value="Formation professionnelle">Formation professionnelle</option>
                            <option value="Service de livraison">Service de livraison</option>
                            <option value="Prospection commerciale">Prospection commerciale</option>
                            <option value="Stratégie de marketing digital">Stratégie de marketing digital</option>
                            <option value="Rédaction de contenu web">Rédaction de contenu web</option>
                            <option value="Conception graphique">Conception graphique</option>
                            <option value="Vente en ligne">Vente en ligne</option>
                            <option value="Autres">Autres </option>
                        </select>
                        <i className="fal fa-chevron-down" />
                    </div>
                    <div className="form-group col-12">
                        <textarea
                            name="message"
                            id="message"
                            required
                            cols={30}
                            rows={3}
                            className="form-control"
                            placeholder="Votre Message"
                            defaultValue={""}
                            value={formData.message}
                            onChange={handleInputChange}
                        />
                        <i className="fal fa-comment" /></div>
                    <div className="form-btn col-12">
                        <button className="as-btn" disabled={formloading}> {formloading ? 'Envoi en cours...' : 'Envoyer'}</button>
                    </div>
                </div>
                <p className="form-messages mb-0 mt-3" />
            </form>
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        // devis: state.devis.devis,
        loading: state.devis.loading
    };
};


export default connect(mapStateToProps)(Devis);
