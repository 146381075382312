import React, { useState } from "react";
import { Row, Col, Modal } from 'antd';
import { Link } from 'react-router-dom';


export default function Job(props) {
    const [selectedJob, setSelectedJob] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (job) => {
        setSelectedJob(job);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedJob(null);
        setIsModalOpen(false);
    };

    console.log({ props })
    return (
        <>
            <div className="col-md-6 col-xl-4">
                <div className="job group p-6 rounded-lg border transition-all duration-500">
                    <div className="flex justify-between items-start">
                        <div>
                            <div className="w-14 h-14 flex items-center justify-center bg-white shadow rounded-full mb-2">
                                <img
                                    alt=""
                                    loading="lazy"
                                    //width={32}
                                    //height={32}
                                    decoding="async"
                                    data-nimg={1}
                                    //className="h-8 w-8"
                                    src="https://cdn-icons-png.flaticon.com/512/3688/3688609.png"
                                    style={{ color: "transparent" }}
                                />
                            </div>
                            <Link to={`/jobs/${props.job.attributes.slug}`}
                                className="text-lg font-semibold transition-all duration-500 company-name"

                            >
                                {props.job.attributes.compagnie.data.attributes.nom}
                            </Link>
                        </div>
                        <div className="flex items-center">
                            <Link to={`/jobs/${props.job.attributes.slug}`} >
                                <span className="bg-deadline inline-block text-white px-4 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500">
                                    <i className="fal fa-calendar-days me-1" />
                                    {props.expiration.slice(0, 10)}
                                </span>
                            </Link>
                            {/* <a
                                className="btn btn-icon rounded-full bg-emerald-600/5 hover:bg-emerald-600 border-emerald-600/10 hover:border-emerald-600 text-emerald-600 hover:text-white ms-1"
                                href="#"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="h-4 w-4"
                                >
                                    <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" />
                                </svg>
                            </a> */}
                            {/* <a
                                className="btn btn-icon rounded-full bg-emerald-600/5 group-hover:bg-emerald-600 border-emerald-600/10 text-emerald-600 group-hover:text-white ms-1"
                                href="/job-apply"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                >
                                    <path d="M17.92,6.62a1,1,0,0,0-.54-.54A1,1,0,0,0,17,6H7A1,1,0,0,0,7,8h7.59l-8.3,8.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L16,9.41V17a1,1,0,0,0,2,0V7A1,1,0,0,0,17.92,6.62Z" />
                                </svg>
                            </a> */}
                        </div>
                    </div>
                    <div className="mt-3">
                        <Link to={`/jobs/${props.job.attributes.slug}`} className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500">

                            {props.title.slice(0, 20)}...
                        </Link>

                        <p className="text-slate-400 mt-2">
                            {props.description.slice(0, 70)}...
                        </p>
                        <div className="mt-3">
                            <Link to={`/jobs/${props.job.attributes.slug}`} className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500">
                                <span className="bg-purple-600/5 hover:bg-purple-600/20 dark:bg-purple-600/10 hover:dark:bg-purple-600/30 inline-block text-purple-600 px-4 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500">
                                    <i className="fal fa-calendar-days me-1" />
                                    {props.job.attributes.jobdomain.data.attributes.nom}
                                </span>
                            </Link>

                            <Link to={`/jobs/${props.job.attributes.slug}`} className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500">
                                <span className="bg-orange-500/5 hover:bg-orange-500/20 dark:bg-orange-500/10 hover:dark:bg-orange-500/30 inline-block text-orange-500 px-4 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500">
                                    {props.job.attributes.experience_level.data.attributes.nom}
                                </span>
                            </Link>
                        </div>
                        <Link to={`/jobs/${props.job.attributes.slug}`}>
                            <span className="bg-emerald-600/5 hover:bg-emerald-600/20 dark:bg-emerald-600/10 hover:dark:bg-emerald-600/30 inline-block text-emerald-600 px-4 text-[14px] font-medium rounded-full mt-2 transition-all duration-500 first-letter inline-flex items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="me-1"
                                >
                                    <path d="M12,2a8,8,0,0,0-8,8c0,5.4,7.05,11.5,7.35,11.76a1,1,0,0,0,1.3,0C13,21.5,20,15.4,20,10A8,8,0,0,0,12,2Zm0,17.65c-2.13-2-6-6.31-6-9.65a6,6,0,0,1,12,0C18,13.34,14.13,17.66,12,19.65ZM12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z" />
                                </svg>
                                {props.job.attributes.pays}
                            </span>
                        </Link>

                    </div>
                    <Link to={`/jobs/${props.job.attributes.slug}`} style={{ cursor: "pointer" }} className="line-btn mt-20" onClick={() => openModal(props.job)}>
                        En Savoir Plus
                    </Link>
                </div>
                {/* <div className="blog-card">
                    <div className="blog-content">
                        <div className="blog-meta">
                            <a href="#">
                                <i className="fal fa-calendar-days" />
                                {props.publication.slice(0, 10)}
                            </a>
                            <a href="#">
                                <i className="fal fa-calendar-days" />
                                {props.expiration.slice(0, 10)}
                            </a>
                        </div>
                        <h5 className="blog-title box-title">
                            <a href="#" onClick={() => openModal(props.job)}>
                                {props.title.slice(0, 20)}...
                            </a>
                        </h5>
                        <p className="blog-text">
                            {props.description.slice(0, 70)}...
                        </p>
                        <a style={{ cursor: "pointer" }} className="line-btn" onClick={() => openModal(props.job)}>
                            En Savoir Plus
                        </a>
                    </div>
                </div> */}
            </div>
            <Modal
                title=""
                centered
                open={isModalOpen}
                onOk={closeModal}
                onCancel={closeModal}
                width={1000}
            >
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-lg-12">
                                <div className="page-single">
                                    <div className="page-content">
                                        <h2 className="h3 page-title">{props.title} ({props.job.title})</h2>
                                        <p>{props.description}</p>
                                        <p style={{
                                            //backgroundColor: "beige",
                                            padding: 15, borderRadius: 10,
                                            border: "1px solid black"
                                        }}>
                                            <Row gutter={20}>
                                                <Col md={8}>
                                                    <p style={{ fontWeight: "bold" }}>
                                                        <h6>Date de publication </h6>
                                                    </p>
                                                    <p>{props.publication.slice(0, 10)}</p>
                                                </Col>
                                                <Col md={8}>
                                                    <p style={{ fontWeight: "bold" }}>
                                                        <h6>Date d'expiration</h6>
                                                    </p>
                                                    <p>{props.expiration.slice(0, 10)}</p>
                                                </Col>
                                                <Col md={8}>
                                                    <p style={{ fontWeight: "bold" }}>
                                                        <h6>Pays</h6>
                                                    </p>
                                                    <p>{props.job?.attributes?.pays}</p>
                                                </Col>
                                                <Col md={8}>
                                                    <p style={{ fontWeight: "bold" }}>
                                                        <h6>Compagnie</h6>
                                                    </p>
                                                    <p>{props.job?.attributes?.compagnie?.data?.attributes?.nom}</p>
                                                </Col>
                                                <Col md={8}>
                                                    <p style={{ fontWeight: "bold" }}>
                                                        <h6>Expérience</h6>
                                                    </p>
                                                    <p>{props.job?.attributes?.experience_level?.data?.attributes?.nom}</p>
                                                </Col>
                                                <Col md={8}>
                                                    <p style={{ fontWeight: "bold" }}>
                                                        <h6>Domaine</h6>
                                                    </p>
                                                    <p>{props.job?.attributes?.jobdomain?.data?.attributes?.nom}</p>
                                                </Col>
                                            </Row>
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Modal>
        </>
    );
};
