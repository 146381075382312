import React, { useEffect } from 'react';
import { fetchJobs } from '../../redux/actions/jobsActions';
import Services from "../../components/Services";
import Breadcumb from '../../components/Breadcumb';
import Pricing from '../../components/Pricing';
import DemandeEmploi from '../../components/DemandeEmploi';
import VideoSection from '../../components/VideoSection';

const DataAnalysePage = (props) => {
    const breadcumb = {
        text: "Data & Analyse",
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "Data & Analyse"
    }

    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />
                <div className="overflow-hidden space">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 pe-xxl-12">
                                <img src="https://online.hbs.edu/Style%20Library/api/resize.aspx?imgpath=/PublishingImages/blog/posts/dataset.png&w=1200&h=630" alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-lg-12">
                                <div className="page-single">
                                    <div className="page-content">
                                        <h2 className="h3 page-title"> </h2>
                                        <h4 className="h4">Nous offrons également cette solution qui est la Data et l’analyse, vous permettant de recueillir, analyser et exploiter les données clients pour des actions stratégiques. </h4>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </section >

                <div className="overflow-hidden space">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-7 pe-xxl-5">
                                <div className="img-box3">
                                    <div className="img1">
                                        <img src="https://datakwery-bucket1.s3.amazonaws.com/featured_image/2021/11/02/7steps.png" alt="About" />
                                    </div>
                                    <div className="shape1">
                                        <img src="../assets/img/shape/about_shape_1.svg" alt="shape" />
                                    </div>
                                    {/* <div className="about-counter1">
                                        <h3 className="counter-title">
                                            <span className="counter-number">24</span>+
                                        </h3>
                                        <span className="counter-text">Years Experience</span>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <div className="title-area mb-30">
                                    <span className="sub-title">
                                        Data & Analyse
                                        <span className="shape right">
                                            <span className="dots" />
                                        </span>
                                    </span>
                                    <h3 className="sec-title">La strategie adéquate pour votre business</h3>
                                </div>
                                <p className="mt-n2 mb-4">
                                "Données et Analyse" couvre un large éventail de sujets. Voici quelques domaines courants dans le cadre des données et de l'analyse :
                                </p>
                                <div className="checklist style2">
                                    <ul>
                                        <li>Collecte de Données</li>
                                        <li>Traitement des Données</li>
                                        <li>Analyse des Données</li>
                                        <li>Big Data</li>
                                    </ul>
                                </div>
                                <div className="btn-wrap mt-30">
                                    <a href="/contact" className="as-btn">
                                        Parlez-nous de votre projet
                                    </a>
                                    <a href="/demander-un-devis" className="as-btn">
                                        demander un devis
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DataAnalysePage;