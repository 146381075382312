import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchRecentBlogs } from '../redux/actions/recentBlogsActions';
import { Link } from 'react-router-dom';
import BlogHomeCard from '../components/BlogHomeCard';

const ActualitesHome = (props) => {
    const { recent_blogs, fetchRecentBlogs, titre } = props;

    useEffect(() => {
        fetchRecentBlogs();
    }, [fetchRecentBlogs]);

    return (
        <>
            <div>
                <section className="space arrow-wrap">
                    <div className="container">
                        <div className="title-area text-center">
                            <span className="sub-title2">
                                <img src="assets/img/shape/subtitle.svg" alt="Icon" /> Récentes Actualités
                            </span>
                            <h2 className="sec-title">Nos Récentes Actualités</h2>

                        </div>
                        <div className="row">
                            {
                                recent_blogs && recent_blogs.map((recent_blog, i) =>
                                    <BlogHomeCard key={i} blog={recent_blog} />
                                )
                            }

                            <div className="col-md-12">
                                <div className="text-center mt-40">
                                    <p className="text-center mb-n2">
                                        Lire tout sur notre actualité içi
                                        <a className="gr-link mx-2" href="/nos-actualites">
                                            <i className="fal fa-arrow-right" /> Toutes nos actualités
                                        </a>
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
            </div>
        </>)
};

const mapStateToProps = (state) => {
    return {
        recent_blogs: state.recent_blogs.recent_blogs,
        loading: state.recent_blogs.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchRecentBlogs: () => dispatch(fetchRecentBlogs())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActualitesHome);