import React from "react";
import config from "../config";
import Breadcumb from '../components/Breadcumb';

export default function ContactPage(props) {
    const breadcumb = {
        text: 'Nous contacter',
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: 'Contact'
    }
    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} marginBottom='0' />
                <div className="space">
                    <div className="container">
                        <div className="row gy-40">
                            <div className="col-xl-5">
                                <div className="contact-info-wrap">
                                    <h2 className="fs-40 border-title">Informations</h2>
                                    <div className="contact-info">
                                        <h4 className="contact-info_title">Téléphone :</h4>
                                        <div className="contact-info_icon"><i className="fal fa-phone" /></div>
                                        <div className="media-body"><span className="contact-info_text">
                                            <a href={`tel:${config.firstContact}`}>{config.firstContact}</a>
                                            <a href={`tel:${config.secondContact}`}>{config.secondContact}</a>
                                            <a href={`tel:${config.thirdContact}`}>{config.thirdContact}</a>
                                        </span>
                                        </div>
                                    </div>
                                    <div className="contact-info">
                                        <h4 className="contact-info_title">E-Mail :</h4>
                                        <div className="contact-info_icon"><i className="fal fa-envelope" /></div>
                                        <div className="media-body"><span className="contact-info_text"><a href="mailto:info@dalky.com">{config.email}</a></span></div>
                                    </div>
                                    <div className="contact-info">
                                        <h4 className="contact-info_title">Localisation :</h4>
                                        <div className="contact-info_icon"><i className="fal fa-location-dot" /></div>
                                        <div className="media-body"><span className="contact-info_text">Ouagadougou,<br />Burkina Faso</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7">
                                <div className="contact-map">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31630.72222738028!2d-1.55811145!3d12.37142785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xe2eb07bda8d64cf%3A0xf91a295a95c4d52f!2sOuagadougou%2C%20Burkina%20Faso!5e0!3m2!1sen!2sbd!4v1651028958211!5m2!1sen!2sbd"
                                        allowFullScreen loading="lazy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="space-bottom overflow-hidden">
                    <div className="container">
                        <div className="row gy-40 align-items-end">
                            <div className="col-xl-8">
                                <h3 className="fs-40 mb-2 mt-n2">Laissez-nous un message</h3>
                                <p className="mb-4">N'hésitez pas à nous contacter pour discuter de vos projets et de vos besoins. Nous serions ravis de vous fournir des informations supplémentaires, de répondre à vos questions et de vous proposer des solutions adaptées à votre entreprise.</p>
                                <form action="#" className="quote-form ajax-contact">
                                    <div className="row">
                                        <div className="form-group col-md-6"><input type="text" required className="form-control" name="name" id="name" placeholder="Identité" /> <i className="fal fa-user" /></div>
                                        <div className="form-group col-md-6"><input type="email" required className="form-control" name="email" id="email" placeholder="Email" /> <i className="fal fa-envelope" /></div>

                                        <div className="form-group col-md-6" style={{ display: "flex" }}>
                                            <div className="form-group col-md-4">
                                                <select
                                                    name="indicatif"
                                                    required
                                                    id="service"
                                                    className="form-select"
                                                //value={formData.indicatif}
                                                //onChange={handleInputChange}
                                                >
                                                    <option value disabled="disabled" selected="selected">Sélectionnez un indicatif</option>
                                                    <option value="+229">Bénin (+229)</option>
                                                    <option value="+226">Burkina Faso (+226)</option>
                                                    <option value="+225">Côte d'Ivoire (+225)</option>
                                                    <option value="+220">Gambie (+220)</option>
                                                    <option value="+233">Ghana (+233)</option>
                                                    <option value="+224">Guinée (+224)</option>
                                                    <option value="+245">Guinée-Bissau (+245)</option>
                                                    <option value="+231">Libéria (+231)</option>
                                                    <option value="+223">Mali (+223)</option>
                                                    <option value="+227">Niger (+227)</option>
                                                    <option value="+234">Nigeria (+234)</option>
                                                    <option value="+221">Sénégal (+221)</option>
                                                    <option value="+232">Sierra Leone (+232)</option>
                                                    <option value="+228">Togo (+228)</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-md-8">
                                                <input
                                                    type="number"
                                                    required
                                                    className="form-control"
                                                    name="phone"
                                                    id="number"
                                                    placeholder="Votre numéro"
                                                //value={formData.phone}
                                                //onChange={handleInputChange}
                                                />
                                                <i className="fal fa-phone" />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6"><select name="subject" required id="subject" className="form-select">
                                            <option value disabled="disabled" selected="selected" hidden>Selectionnez un service
                                            </option>
                                            <option value="Mise en relation emploi">Mise en relation emploi</option>
                                            <option value="Formation professionnelle">Formation professionnelle</option>
                                            <option value="Service de livraison">Service de livraison</option>
                                            <option value="Prospection commerciale">Prospection commerciale</option>
                                            <option value="Stratégie de marketing digital">Stratégie de marketing digital</option>
                                            <option value="Rédaction de contenu web">Rédaction de contenu web</option>
                                            <option value="Conception graphique">Conception graphique</option>
                                            <option value="Vente en ligne">Vente en ligne</option>
                                            <option value="Autres">Autres</option>
                                        </select>
                                            <i className="fal fa-chevron-down" />
                                        </div>
                                        <div className="form-group col-12"><textarea name="message" id="message" required cols={30} rows={3} className="form-control" placeholder="Message" defaultValue={""} /> <i className="fal fa-comment" /></div>
                                        {/* <input type="checkbox" id="consent" name="consent" />
                                        <label for="consent">J'accepte que mes données soient utilisées dans le cadre du traitement de ma demande</label> */}
                                        <div className="form-btn col-12"><button className="as-btn">Envoyer</button></div>
                                    </div>
                                    <p className="form-messages mb-0 mt-3" />
                                </form>
                            </div>
                            <div className="col-xl-3">
                                <div className="contact-img"><img src="https://website-assets-fd.freshworks.com/attachments/ckjv6jx7700lscjfzuyspsnv0-customer-service-team-training.one-half.png" alt="vector" /></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    );
}
