import React, { useEffect } from 'react';
import { fetchJobs } from '../../redux/actions/jobsActions';
import Services from "../../components/Services";
import Breadcumb from '../../components/Breadcumb';
import Pricing from '../../components/Pricing';
import DemandeEmploi from '../../components/DemandeEmploi';
import VideoSection from '../../components/VideoSection';

const MiseEnRelationPage = (props) => {
    const breadcumb = {
        text: "Fonctionnement de nos formations",
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "Fonctionnement de nos formations"
    }

    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />

                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-lg-12">
                                <div className="page-single">
                                    <img src="https://hahnpropertiesmanagement.com/content/uploads/elementor/thumbs/office-exterior-q1jeox5fe8cse32682bg89i06y5845q051ako6gmc0.jpg" />
                                    <h2 className="h3 page-title">Formations certifiantes</h2>
                                    <div className="page-content">
                                        <h4 className="h4">Compréhension</h4>
                                        <p>Une formation certifiante est une formation sanctionnée par un certificat en fin de cursus. Un certificat est très important pour la carrière professionnelle d'un employé car il l'aidera dans sa progression. À la fin de notre formation certifiante, nous décernons des certificats à ceux qui ont terminé la formation et qui ont ainsi réussi à un certain pourcentage les différentes évaluations. Tous nos certificats sont reconnus dans le domaine professionnel.</p>
                                        <h4 className="h4">Les critères de sélection pour une formation certifiante chez nous</h4>
                                        <p>
                                            Avant de faire une certification chez nous, il est impératif de vérifier si cela répond aux critères de sélection. Étant donné que la formation donne un titre professionnel, ce titre doit figurer sur la liste des titres autorisés par la Commission Nationale de la Certification. En plus de cela, la formation suivie doit avoir un bon niveau de classement autorisé par la CNC.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-lg-12">
                                <div className="page-single">
                                    <div className="page-content">
                                        <h2 className="h3 page-title">Formations inter-entreprises</h2>
                                        <h4 className="h4">Compréhension</h4>
                                        <p>Comme son nom l’indique, nos formations inter-entreprises réunient des
                                            salariés de différentes entreprises. L’avantage de ce type de formation est qu’elle s’effectue hors des lieux, hors du cadre habituel de votre entreprise. elle
                                            constitue une occasion de prendre du recul sur son quotidien opérationnel métier, de s’affranchir de l’environnement de travail auquel on est habitué pour se retrouver en situation propice au partage et à la collaboration entre
                                            participants.</p>

                                        <div className="space">
                                            <h2 className="h3 page-title">Formations sur mesure/à la carte</h2>
                                            <h4 className="h4">Compréhension</h4>
                                            <p>La formation à la carte ou sur mesure en entreprise est plus adapté pour former
                                                les collaborateurs à de nouveaux services ou les familiariser avec un nouveau
                                                produit.
                                                <br />
                                                Les avantages des formations à la carte :
                                            </p>

                                            <div className="ticklist">
                                                <ul>
                                                    <li>Elles sont programmées selon les besoins du client : elles peuvent être
                                                        programmées en soir et durant les week-ends ;</li>
                                                    <li>Elles ont une approche personnalisée car il y a des cas pratiques
                                                        particulièrement orientés vers le secteur d'activité de l’entreprise ;</li>
                                                    <li>Elles peuvent se tenir dans les locaux de l’entreprise ou dans tout autre lieu (hôtel, salle de conférence,) proposé par l’entreprise ; ou bien dans
                                                        les locaux de ADIVE ;</li>

                                                </ul>
                                            </div>
                                        </div>

                                        <div className="space">
                                            <h2 className="h3 page-title">Formations E-learning</h2>
                                            <h4 className="h4">Compréhension</h4>
                                            <p>Développez vos compétences et boostez votre performance grâce à nos formations e-learning ADIVE. Nos programmes individualisés, flexibles et interactifs vous permettent d'apprendre à votre rythme et de bénéficier du savoir-faire de ADIVE dans le domaine de la formation en ligne.
                                            </p>
                                        </div>

                                        <div className="space">
                                            <h2 className="h3 page-title">Formations Classe virtuelle</h2>
                                            <h4 className="h4">Compréhension</h4>
                                            <p>ADIVE vous propose une large gamme de formation en classe virtuelle. Cette méthode consiste à utiliser un système de visioconférence qui réunit le ou les formateurs et les apprenants. Ils peuvent se voir, discuter entre eux, participer à des activités interactives et s’échanger des documents. En effet, depuis le développement et la démocratisation du digital, le fait de travailler ou d’étudier sur Internet séduit de plus en plus. Nous permettons aux salariés comme aux particuliers d’apprendre de chez eux. C’est ce qui explique le succès de la formation à distance.
                                            </p>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </section >
            </div >
        </>
    );
}

export default MiseEnRelationPage;