import React, { useEffect } from 'react';
import { fetchJobs } from '../../redux/actions/jobsActions';
import Services from "../../components/Services";
import Breadcumb from '../../components/Breadcumb';
import Pricing from '../../components/Pricing';
import DemandeEmploi from '../../components/DemandeEmploi';
import VideoSection from '../../components/VideoSection';

const MarketingPage = (props) => {
    const breadcumb = {
        text: "Stratégie de marketing digital",
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "Stratégie de marketing digital"
    }

    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />
                <div className="overflow-hidden space">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-7 pe-xxl-5">
                                <div className="img-box3">
                                    <div className="img1">
                                        <img src="https://www.keacrea.com/images/pages/facebook/avantage-reseaux-sociaux.png" alt="About" />
                                    </div>
                                    <div className="shape1">
                                        <img src="../assets/img/shape/about_shape_1.svg" alt="shape" />
                                    </div>
                                    {/* <div className="about-counter1">
                                        <h3 className="counter-title">
                                            <span className="counter-number">24</span>+
                                        </h3>
                                        <span className="counter-text">Years Experience</span>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <div className="title-area mb-30">
                                    <span className="sub-title">
                                        Marketing Digital
                                        <span className="shape right">
                                            <span className="dots" />
                                        </span>
                                    </span>
                                    <h3 className="sec-title">La stratégie adéquate pour votre business</h3>
                                </div>
                                <p className="mt-n2 mb-4">
                                    {/* All technology is perfect for the best business solutions &amp; we
                                    offer help to achieve your goals. */}
                                </p>
                                <div className="checklist style2">
                                    <ul>
                                        <li>Analyse de la stratégie existante et apport des améliorations</li>
                                        <li>Analyse des besoins et objectifs pour la mise en place d’une stratégie toute neuve de marketing digital</li>
                                        <li>Suivi et contrôle des indicateurs de performance et ajustement de la stratégie</li>
                                    </ul>
                                </div>
                                <div className="btn-wrap mt-30">
                                    <a href="/demander-un-devis" className="as-btn">
                                        demander un devis
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-lg-12">
                                <div className="page-single">
                                    <div className="page-content">
                                        <h2 className="h3 page-title">Stratégie de Marketing digital </h2>
                                        <h4 className="h4"></h4>
                                        <p>La stratégie de Marketing digital, au cœur de notre conseil, définit les objectifs et moyens digitaux pour atteindre les résultats souhaités, en tenant compte des forces internes et des contraintes externes de l'entreprise. Pour prospérer, une entreprise doit déployer une stratégie de Marketing digital redoutable. Il est indispensable de mettre en place une stratégie digitale pour organiser vos actions de communication sur le web. Il y a plusieurs étapes à mener à bien afin d’atteindre vos objectifs :</p>
                                        <div className="list-column">
                                            <div className="ticklist">
                                                <ul>
                                                    <li>Audit de l’existant : nous prenons le temps d’analyser votre entreprise. Vos moyens de communication actuels ? Les médias que vous utilisez ? Comment la concurrence communique ?</li>
                                                    <li>La cible : il est important de définir vos personas, ce sont des profils type de vos différentes cibles. cela vous aidera à savoir à qui vous souhaitez vous adresser et comment. </li>
                                                    <li>Les objectifs : afin de mesurer des résultats, il faut commencer par définir un ou plusieurs objectifs. ils doivent être clairs, réalisables, mesurables et définis temporellement. </li>
                                                    <li>La sélection des supports : une fois que les premières étapes sont faites, nous allons réfléchir aux supports de communication à privilégier.</li>
                                                    <li>Planification et budget : nous vous aidons à vous organiser en définissant un budget et également les dates de vos différentes actions. </li>
                                                    <li>Mettre en place la stratégie : cette étape est assez conséquente car il faut réaliser tous les visuels, les campagnes, les contenus, etc. Pas d’inquiétude, nous en avons la maitrise pour votre bonheur. </li>
                                                    <li>Mesurer les performances : nous mettons également en place un tableau de bord vous permettant d’analyser régulièrement vos résultats grâce à des KPI afin de savoir si votre stratégie a été efficace. </li>
                                                </ul>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </section >
            </div>
        </>
    );
}

export default MarketingPage;