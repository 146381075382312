import React, { useEffect } from 'react';
import Breadcumb from '../components/Breadcumb';
import BiblioCard from '../components/BiblioCard';
import Skelet from '../components/Skeleton';
import { connect } from 'react-redux';
import { fetchBiblios } from '../redux/actions/bibliosActions';

const BiblioPage = (props) => {
    const { loading, biblios, fetchBiblios } = props;
    useEffect(() => {
        fetchBiblios();
    }, [fetchBiblios]);

    const breadcumb = {
        text: 'Adive espace vente en ligne',
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: 'Espace vente en ligne'
    }

    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />
                <section className="biblios-page space-bottom space-extra-bottom">
                    <div className="container">
                        <div className="row gy-4">
                            {!loading && biblios.map((biblio) => (
                                <BiblioCard biblio={biblio} />
                            ))}
                            {loading && <Skelet />}

                        </div>
                        {/* <Pagination /> */}
                    </div>
                </section>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        biblios: state.biblios.biblios,
        loading: state.biblios.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBiblios: () => dispatch(fetchBiblios())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BiblioPage);