import React, { useState } from "react";
import config from "../config";

const Pricing = ({ }) => {
    return (
        <>
            <section className="space mb-50 px-5" style={{ backgroundImage: 'url("https://eduvibe.html.devsvibe.com/assets/images/bg/home-five-hiw.jpg")' }} >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="title-area text-center">
                                <span className="sub-title">Nos Plans tarifaire </span>
                                <h2 className="sec-title">Nos Tarifs sur les packs emploi </h2>
                                <p className="text-center">
                                    Nous vous proposons une gamme complète de services emploi tarifs adaptés à vos besoins et à votre budget. Voici un aperçu de nos meilleures offres tarifaires <br />
                                    Tous ces services sont disponibles uniquement en ligne. Pour un besoin en présentiel pour ces services, veuillez discuter avec nous.
                                </p>

                            </div>
                        </div>
                    </div>
                    <div className="filter-active-cat1">
                        <div className="filter-item w-100 cat1">
                            <div className="row gy-40 justify-content-center">
                                <div className="col-xl-4 col-md-6">
                                    <div className="price-card">
                                        <h3 className="price-card_title">
                                            Ponctuel <span className="name-shape" />
                                        </h3>
                                        <h4 className="price-card_price">
                                            <span className="currency"></span>
                                        </h4>
                                        <div className="price-card_content">
                                            <div className="available-list">
                                                <ul style={{ textAlign: 'justify' }}>
                                                    <li>Alertes des offres d’emploi (2000 FCFA : mensuel)</li>
                                                    <li>CV Pro (5000 FCFA : ponctuel)</li>
                                                    <li>Lettre de motivation (3000 FCFA : ponctuel)</li>
                                                    <li>Formation en entretien d’embauche (7000 FCFA : ponctuel)</li>
                                                    <li>Préparation pratique au test de recrutement s’il y en a (5000 FCFA : ponctuel)</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <a href="#" className="as-btn">
                                            Acheter
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                    <div className="price-card">
                                        <h3 className="price-card_title">
                                            Mensuel <span className="name-shape" />
                                        </h3>
                                        <h4 className="price-card_price">
                                            <span className="currency"></span> 9 900 FCFA
                                        </h4>
                                        <div className="price-card_content">
                                            <div className="available-list">
                                                <ul style={{ textAlign: 'justify' }}>
                                                    <li>Alertes des offres d'emploi</li>
                                                    <li>CV Pro</li>
                                                    <li>Lettre de motivation</li>
                                                    <li>Formation en entretien d'embauche</li>
                                                    <li>Préparation pratique au test</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <a href="#" className="as-btn">
                                            Acheter
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                    <div className="price-card active">
                                        <h3 className="price-card_title">
                                            Semestriel <span className="name-shape" />
                                        </h3>
                                        <h4 className="price-card_price">
                                            <span className="currency"></span> 39 900 FCFA
                                        </h4>
                                        <div className="price-card_content">
                                            <div className="available-list">
                                                <ul style={{ textAlign: 'justify' }}>
                                                    <li>Alertes des offres d'emploi</li>
                                                    <li>CV Pro</li>
                                                    <li>Lettre de motivation</li>
                                                    <li>Formation en entretien d'embauche</li>
                                                    <li>Préparation pratique au test</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <a href="#" className="as-btn">
                                            Acheter
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                    <div className="price-card">
                                        <h3 className="price-card_title">
                                            Annuel <span className="name-shape" />
                                        </h3>
                                        <h4 className="price-card_price">
                                            <span className="currency"></span> 49 900 FCFA
                                        </h4>
                                        <div className="price-card_content">
                                            <div className="available-list">
                                                <ul style={{ textAlign: 'justify' }}>
                                                    <li>Alertes des offres d'emploi</li>
                                                    <li>CV Pro</li>
                                                    <li>Lettre de motivation</li>
                                                    <li>Formation en entretien d'embauche</li>
                                                    <li>Préparation pratique au test</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <a href="#" className="as-btn">
                                            Acheter
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default Pricing;
