import React, { useState } from "react";
import CountUp from 'react-countup';
import Breadcumb from '../components/Breadcumb';

export default function AboutPage(props) {
    const breadcumb = {
        text: "A propos de nous",
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "A Propos"
    }

    const [activeTab, setActiveTab] = useState('tab1');
    const [isOpen, setOpen] = useState(false);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />
                <div className="overflow-hidden space">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 pe-xxl-5 mb-30 mb-lg-0">
                                <div className="img-box4">
                                   {/*  <div className="left">
                                        <div className="img1"><img src="https://findafro.com/wp-content/uploads/2021/05/Venture-Noire-lance-un-Programme-de-Subvention-pour-les-Entrepreneurs-Noirs.jpg" width={100} alt="About" /></div>
                                        <div className="shape1"> <img src="assets/img/shape/about_3_2.png" alt="shape" /> </div>
                                    </div> */}
                                    <div className="right">
                                        <div className="shape2">{/* <img src="assets/img/shape/about_3_1.png" alt="shape" /> */}</div>
                                        <div className="img2"><img src="https://static.vecteezy.com/ti/vecteur-libre/p1/4566324-femme-d-affaires-portant-costumes-noirs-en-action-pose-caucasien-asiatique-et-noir-illustration-femme-d-entreprise-vectoriel.jpg" alt="About" /></div>
                                    </div>
                                    <div className="about-box1">
                                        <div className="icon"><img src="assets/img/icon/award_1.svg" alt="icon" /></div>
                                        <p className="text" style={{ textAlign: 'center' }}>Meilleure académie</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="title-area mb-30"><span className="sub-title">A PROPOS DE NOUS <span className="shape right"><span className="dots" /></span></span>
                                    <h2 className="sec-title">Meilleure Académie du Digital & Vente</h2>
                                </div>
                                <p className="fs-md fw-medium mt-n2 mb-4">Nous sommes une entreprise spécialisée dans la fourniture de services liés à la formation, au digital et à l’emploi.</p>
                                <p className="fs-md fw-medium mt-n2 mb-4">Nous offrons une gamme de services variés pour aider les entreprises à atteindre leurs objectifs commerciaux et à réussir leur transformation digitale.</p>
                                {/* <div className="profile-wrap">
                                    <div className="about-profile">
                                        <div className="avater"><img src="assets/img/normal/about_avater.jpg" alt="avater" /></div>
                                        <div className="media-body">
                                            <h3 className="title">Michel Richard</h3><span className="desig">CEO of Company</span>
                                        </div>
                                    </div>
                                    <div className="signature"><img src="assets/img/normal/signature.svg" alt="signature" /></div>
                                </div><a href="#" className="as-btn">Lire Plus</a> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="space" style={{ backgroundImage: "url(assets/img/bg/testi_bg_3.png)" }}>
                    <div className="container">
                        <div className="row
              justify-content-between">
                            <div className="col-xl-auto
                  text-xl-start text-center">
                                <div className="custom-box1">
                                    <div className="title-area mb-30"><span className="sub-title2"><img src="assets/img/shape/subtitle.svg" alt="Icon" /> En savoir plus sur nous</span>
                                        <h2 className="sec-title">Académie Digitale, Qualité & Expérience</h2>
                                    </div>
                                    <p className="mt-n2 mb-30">Nous sommes une entreprise spécialisée dans la fourniture de services liés à la formation, à l’emploi, à la transformation digitale et à l’optimisation des stratégies marketing digital et de communication digitale. </p>
                                    <div className="rounded-10"><img className="w-100" src="assets/img/home/shutterstock_1189315729.jpg" alt="about" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-auto mt-5 mt-xl-0">
                                <div className="custom-box2">
                                    <div className="tab-menu3 filter-menu-active">
                                        <button onClick={() => handleTabClick('tab1')} className={activeTab === 'tab1' ? 'active' : ''} type="button">Notre Mission</button>
                                        <button onClick={() => handleTabClick('tab2')} className={activeTab === 'tab2' ? 'active' : ''} type="button">Notre Vision</button>
                                        <button onClick={() => handleTabClick('tab3')} className={activeTab === 'tab3' ? 'active' : ''} type="button"> Nos Valeurs</button>
                                        <button onClick={() => handleTabClick('tab4')} className={activeTab === 'tab4' ? 'active' : ''} type="button"> Histoire</button>
                                    </div>
                                    <div className="filter-active-cat1">
                                        {
                                            activeTab === 'tab1' &&
                                            <div className="filter-item w-100 cat1">
                                                <h3 className="h4 mb-20">Notre Mission</h3>
                                                <p className="mb-30">Notre mission est de fournir des solutions numériques faciles, des formations et des services liés à l’emploi aux particuliers et aux entreprises pour les rendre plus productifs et plus performants.

                                                </p>
                                                <div className="mission-list">
                                                    <div className="rounded-10"><img src="assets/img/home/mission.jpg" alt="Mission" />
                                                    </div>
                                                    <div className="ticklist">
                                                        <ul>
                                                            <li>Stratégie numérique</li>
                                                            <li>Conception de sites web</li>
                                                            <li>Conception d’application et logiciels web</li>
                                                            <li>Optimisation des conversions</li>
                                                            <li>Marketing de contenu</li>
                                                            <li>Analyse et suivi des performances</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            activeTab === 'tab2' &&
                                            <div className="filter-item w-100 cat2">
                                                <h3 className="h4 mb-20">Notre Vision</h3>
                                                <p className="mb-30">Notre vision est de devenir dans cinq (05) ans le partenaire de référence pour les entreprises souhaitant réussir leur transition numérique et optimiser leurs performances. Nous aspirons à être reconnus pour notre expertise, notre créativité et notre capacité à fournir des solutions personnalisées et efficaces.</p>
                                            </div>
                                        }
                                        {
                                            activeTab === 'tab3' &&
                                            <div className="filter-item w-100 cat3">
                                                <h3 className="h4 mb-20">Nos Valeurs</h3>
                                                <p className="mb-30">Ces valeurs fondamentales peuvent nous aider à guider notre académie et à maintenir
                                                    une culture cohérente tout en fournissant des services de qualité à nos clients.</p>
                                                <div className="mission-list">
                                                    <div className="rounded-10"><img src="assets/img/home/valeurs.png" alt="Valeurs" /></div>
                                                    <div className="ticklist">
                                                        <ul>
                                                            <li>Créativité </li>
                                                            <li>Innovation </li>
                                                            <li>Professionnalisme </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            activeTab === 'tab4' &&
                                            <div className="filter-item w-100 cat3">
                                                <h3 className="h4 mb-20">Histoire</h3>
                                                <p className="mb-30">Notre académie du digital et de la vente a commencé modestement il y a plusieurs années avec une équipe passionnée
                                                    du marketing numérique. <br /><br />

                                                    Au fil du temps, nous avons développé notre expertise et élargi nos services,
                                                    travaillant avec divers clients pour les aider à réussir leur transformation
                                                    digitale.<br /><br />
                                                    Grâce à notre engagement envers l'excellence, notre approche collaborative et
                                                    nos résultats concrets, nous sommes devenus une académie reconnue, offrant
                                                    des solutions personnalisées dans différents domaines du marketing, de la
                                                    communication digitale et de l’emploi.<br /><br />
                                                    Nous continuons à écrire notre histoire en restant à l'avant-garde de l'industrie
                                                    et en aidant nos clients à prospérer dans l'économie numérique en constante
                                                    évolution.</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space bg-smoke overflow-hidden" data-bg-src="assets/img/bg/why_bg_3.png">
                    <div className="container">
                        <div className="row flex-row-reverse">
                            <div className="col-xl-6">
                                <div className="img-box5">
                                    <div className="img1"><img src="assets/img/about/black-people-reading-economic-news.jpg" alt="image" /></div>
                                    <div className="shape1"><img src="assets/img/shape/dot_1.png" alt="shape" /></div>
                                    <div className="video-box3" data-overlay="overlay1" data-opacity={5}>
                                        <img src="assets/img/about/strategie 1.jpg" alt="image" />
                                        {/* <a href="https://www.youtube.com/watch?v=_sI_Ps7JSEk" className="play-btn popup-video">
                                            <i className="fas fa-play" />
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="title-area mb-30"><span className="sub-title">Pourquoi Nous Choisir</span>
                                    <h2 className="sec-title">Solution numérique</h2>
                                </div>
                                <p className="mt-0 mb-4">Il y a plusieurs raisons pour lesquelles vous devriez envisager de choisir notre académie. Voici quelques points forts qui peuvent vous aider à prendre une décision :
                                </p>
                                <div className="info-media">
                                    <div className="info-media_icon"><i className="far fa-lightbulb-on" /></div>
                                    <div className="media-body">
                                        <h3 className="h6 info-media_title">Expertise & Expérience</h3>
                                        <p className="info-media_text">Notre équipe est composée de professionnels qualifiés et expérimentés dans les domaines de la formation, des études de marché, de la création digitale, du marketing digital, de la communication digitale et de la vente en ligne.</p>
                                    </div>
                                </div>
                                <div className="info-media">
                                    <div className="info-media_icon"><i className="far fa-circle-dollar" /></div>
                                    <div className="media-body">
                                        <h3 className="h6 info-media_title">Gamme complète de Services</h3>
                                        <p className="info-media_text">Notre académie offre une gamme complète de services dans le domaine de la formation, du digital et de l’emploi.</p>
                                    </div>
                                </div>
                                <div className="info-media mb-0">
                                    <div className="info-media_icon"><i className="far fa-headset" /></div>
                                    <div className="media-body">
                                        <h3 className="h6 info-media_title">Engagement envers la satisfaction du client</h3>
                                        <p className="info-media_text">La satisfaction de nos clients est notre priorité absolue.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space counterSection">
                    <div className="container z-index-common">
                        <div className="row gy-40 justify-content-between">
                            <div className="col-sm-6 col-xl-auto">
                                <div className="counter-card">
                                    <div className="counter-card_icon"><i className="fa-thin fa-file-check" /></div>
                                    <div className="media-body">
                                        <h2 className="counter-card_number">+<CountUp end={100} duration={5} /></h2>
                                        <p className="counter-card_text">Partenaires Actifs</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-auto">
                                <div className="counter-card">
                                    <div className="counter-card_icon"><i className="fa-thin fa-shield-check" /></div>
                                    <div className="media-body">
                                        <h2 className="counter-card_number">+<CountUp end={500} duration={5} /></h2>
                                        <p className="counter-card_text">Clients Actifs</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-auto">
                                <div className="counter-card">
                                    <div className="counter-card_icon"><i className="fa-thin fa-mug-hot" /></div>
                                    <div className="media-body">
                                        <h2 className="counter-card_number">+<CountUp end={95} duration={5} />%</h2>
                                        <p className="counter-card_text">Clients Satisfaits</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-auto">
                                <div className="counter-card">
                                    <div className="counter-card_icon"><i className="fa-thin fa-face-smile" /></div>
                                    <div className="media-body">
                                        <h2 className="counter-card_number">+<CountUp end={1000} duration={5} /></h2>
                                        <p className="counter-card_text">Témoignages</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-smoke position-relative">
                    <div className="img-right skill-half"><img src="assets/img/about/skill_img_2.png" alt="skill img" /></div>
                    <div className="container z-index-common">
                        <div className="row space-top space-bottom mt-n5 mt-xl-0">
                            <div className="col-xl-6 col-lg-9">
                                <div className="title-area mb-30"><span className="sub-title">Nos Compétences</span>
                                    <h2 className="sec-title">Nous sommes plus créatifs que jamais </h2>
                                </div>
                                <p className="mt-n2 mb-4">En tant qu'académie du digital et de la vente, nous avons développé un large éventail de compétences dans les domaines clés de la formation, du marketing digital, de la vente en ligne et de l’emploi.
                                    <br />Voici quelques-unes de nos compétences principales.</p>
                                <div className="skill-feature">
                                    <p className="skill-feature_title">Stratégie de marketing digital</p>
                                    <div className="progress">
                                        <div className="progress-bar" style={{ width: '98%' }}>
                                            <div className="progress-value"><CountUp end={98} duration={5} />%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="skill-feature">
                                    <p className="skill-feature_title">Stratégie de communication digitale</p>
                                    <div className="progress">
                                        <div className="progress-bar" style={{ width: '97%' }}>
                                            <div className="progress-value"><CountUp end={97} duration={5} />%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="skill-feature">
                                    <p className="skill-feature_title">Gestion des médias sociaux</p>
                                    <div className="progress">
                                        <div className="progress-bar" style={{ width: '96%' }}>
                                            <div className="progress-value"><CountUp end={96} duration={5} />%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="skill-feature">
                                    <p className="skill-feature_title">Analyse des données et suivi des performances</p>
                                    <div className="progress">
                                        <div className="progress-bar" style={{ width: '94%' }}>
                                            <div className="progress-value"><CountUp end={94} duration={5} />%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="skill-feature">
                                    <p className="skill-feature_title">Conception de logiciels web</p>
                                    <div className="progress">
                                        <div className="progress-bar" style={{ width: '92%' }}>
                                            <div className="progress-value"><CountUp end={92} duration={5} />%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="space-bottom" style={{ marginTop: 100 }}>
                    <div className="container">
                        <div className="cta-wrap" data-bg-src="assets/img/bg/cta_bg_2.png">
                            <div className="row gx-0 align-items-center">
                                <div className="col-lg text-center text-lg-start">
                                    <p className="mb-10 mt-n2">Nous sommes toujours disponibles pour répondre à vos questions </p>
                                    <h2 className="mb-n3">Vous avez besoin de notre aide ?</h2>
                                </div>
                                <div className="col-lg-auto mt-35 mt-lg-0">
                                    <div className="text-center"><a href="/contact" className="as-btn style3">Contactez-Nous</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
            </div>

        </>
    );
}
