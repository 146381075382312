import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchBlogs } from '../redux/actions/blogsActions';
import { useSelector } from 'react-redux';
import Skelet from '../components/Skeleton';
import Breadcumb from '../components/Breadcumb';
import { useParams } from 'react-router-dom';
import Share from '../components/ShareSocialMedia';
import MarkdownRenderer from '../components/MarkdownRenderer';

const JobDetailsPage = (props) => {
    const { slug } = useParams();
    const savedJobs = useSelector((state) => state.jobs.jobs);
    const job = savedJobs.find((job) => job.attributes.slug === slug);
    // console.log({ job });
    // console.log({ savedJobs })


    let jobTitle = job && job.attributes.titre;
    const breadcumb = {
        text: jobTitle,
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "Emplois"
    }
    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />
                <section className=" space-extra-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-8 col-lg-7">
                                <div className="page-single">
                                    <div className="page-content">
                                        {/* <h2 className="page-title">{job.attributes.titre}</h2> */}
                                        <h2 className="page-title">Description de l'offre</h2>

                                        <p className="mb-30">
                                            <MarkdownRenderer markdownContent={job.attributes.description} />

                                        </p>

                                    </div>
                                    <h4 className="h4">Comment postuler ?</h4>
                                    <div className="accordion-area accordion" id="faqAccordion">
                                        <div className="accordion-card active">
                                            <div className="accordion-header" id="collapse-item-1"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
                                                <span className="text-theme">
                                                    1.</span> Bénéficiez de notre assistance</button></div>
                                            <div id="collapse-1" className="accordion-collapse collapse show" aria-labelledby="collapse-item-1" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">Un CV professionnel bien conçu et bien structuré crée
                                                        une excellente première impression auprès des recruteurs et des employeurs
                                                        potentiels. Il reflète votre professionnalisme et votre engagement envers
                                                        votre carrière.</p><br />
                                                    <p>Alors, qu'attendez-vous ? Bénéficiez de notre service de conception de
                                                        CV professionnel dès maintenant pour faire la différence auprès des recruteurs.</p>
                                                    <div className="form-btn col-12">
                                                        <button className="as-btn">
                                                            Continuez vers la création du CV pro
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-card">
                                            <div className="accordion-header" id="collapse-item-2"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                                <span className="text-theme">
                                                    2.</span> Continuez sans notre assistance</button></div>
                                            <div id="collapse-2" className="accordion-collapse collapse" aria-labelledby="collapse-item-2" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">Vous disposez déjà d'un CV professionnel bien conçu et bien structuré et
                                                        vous désirez postuler à cette offre avec ledit CV.</p><br />
                                                    <div className="form-btn col-12">
                                                        <button className="as-btn">
                                                            Postulez directement avec votre CV
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-lg-5">
                                <aside className="sidebar-area">
                                    <div className="widget widget_info">
                                        <h3 className="widget_title">Détails</h3>
                                        <div className="info-list">
                                            <ul>
                                                {job?.attributes.urgent && <li className='urgent'>
                                                    <i class="fa-light fa-circle-exclamation"></i>
                                                    <div>
                                                        <strong className="title">Urgent</strong>
                                                        {/* <span className="text">{job.attributes.urgent}</span> */}
                                                    </div>
                                                </li>}
                                                <li>
                                                    <i className="fa-light fa-tv" />
                                                    <div>
                                                        <strong className="title">Domaine</strong>
                                                        <span className="text">{job?.attributes.jobdomain.data.attributes.nom}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <i className="fa-light fa-user-check" />
                                                    <div>
                                                        <strong className="title">Type</strong>
                                                        <span className="text">{job?.attributes.jobtype.data.attributes.nom}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <i className="fa-light fa-suitcase" />
                                                    <div>
                                                        <strong className="title">Expérience</strong>
                                                        <span className="text">{job.attributes.experience_level.data.attributes.nom}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <i className="fa-light fa-hourglass" />
                                                    <div>
                                                        <strong className="title">Date d'expiration</strong>
                                                        <span className="text">{job?.attributes.date_expiration.slice(0, 10)}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <i className="fa-light fa-building" />
                                                    <div>
                                                        <strong className="title">Compagnie</strong>
                                                        <span className="text">{job?.attributes.compagnie.data.attributes.nom}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <i className="fa-light fa-location-dot" />
                                                    <div>
                                                        <strong className="title">Pays</strong>
                                                        <span className="text">{job?.attributes.pays}</span>
                                                    </div>
                                                </li>

                                                <li>
                                                    <i className="fa-light fa-calendar-days" />
                                                    <div>
                                                        <strong className="title">Date de publication</strong>
                                                        <span className="text">{job?.attributes?.date_publication.slice(0, 10)}</span>
                                                    </div>
                                                </li>
                                                {/* <a className="fullButton as-btn" href="/a-propos-de-nous">En Savoir Plus</a> */}
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    );
}

export default JobDetailsPage;