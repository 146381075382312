import React, { useEffect } from 'react';
import { fetchJobs } from '../../redux/actions/jobsActions';
import Services from "../../components/Services";
import Breadcumb from '../../components/Breadcumb';
import Pricing from '../../components/Pricing';
import DemandeEmploi from '../../components/DemandeEmploi';
import VideoSection from '../../components/VideoSection';

const MiseEnRelationPage = (props) => {
    const breadcumb = {
        text: "Conception graphique & contenu web",
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "Conception graphique & contenu web"
    }

    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />
                <div>
                    <div className="position-relative mb-50" style={{ backgroundColor: "aliceblue" }}>
                        <div className="row gx-0">
                            <div className="col-xl-auto">
                                <div className="video-box3" data-overlay="overlay1" data-opacity={5}>
                                    <img src="https://www.o-communication.com/wp-content/uploads/2019/04/concept-graphique-design-agence-communication.jpg" alt="video" />{" "}
                                </div>
                            </div>
                            <div className="col-xl-auto">
                                <div className="why-box">
                                    <div className="title-area mb-30">
                                        <span className="sub-title text-white">Notre service</span>
                                        <h3 className="sec-title text-white">
                                            Contenu web et Conception graphique
                                        </h3>
                                    </div>
                                    <p className="mt-n2 mb-30 text-white">

                                    </p>
                                    <div className="info-media-wrap">
                                        <div className="info-media">
                                            <div className="info-media_icon">
                                                <i className="far fa-lightbulb-on" />
                                            </div>
                                            <div className="media-body">
                                                <h3 className="h6 info-media_title">Conception et Impression</h3>
                                                <p className="info-media_text">
                                                    Conception et Impression d’affiches publicitaires, de panneaux publicitaires, de catalogues professionnels, de dépliants, de calendriers, de flyers, de logos, de cartes de visites
                                                </p>
                                            </div>
                                        </div>
                                        <div className="info-media">
                                            <div className="info-media_icon">
                                                <i className="far fa-circle-dollar" />
                                            </div>
                                            <div className="media-body">
                                                <h3 className="h6 info-media_title">Rédaction de contenu web</h3>
                                                <p className="info-media_text">
                                                    Explorez notre gamme exclusive de produits écologiques pour un mode de vie plus durable. Des articles pratiques et élégants conçus pour réduire votre empreinte écologique sans compromettre le style. Découvrez dès maintenant nos nouveautés et faites un pas vers un avenir plus vert !
                                                </p>
                                            </div>
                                        </div>
                                        {/* <div className="info-media mb-0">
                                            <div className="info-media_icon">
                                                <i className="far fa-headset" />
                                            </div>
                                            <div className="media-body">
                                                <h3 className="h6 info-media_title">Support disponible</h3>
                                                <p className="info-media_text">
                                                    Nous sommes là pour vous accompagner à chaque étape de votre parcours. Que vous ayez besoin d'aide, de conseils ou simplement d'une oreille attentive, notre équipe de support est disponible pour répondre à toutes vos questions et résoudre vos problèmes.
                                                </p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="counter-grid-wrap">
                            <div className="counter-grid">
                                <h2 className="counter-grid_number">
                                    <span className="counter-number">8.2</span>k
                                </h2>
                                <p className="counter-grid_text">Projets accomplis</p>
                            </div>
                            <div className="counter-grid">
                                <h2 className="counter-grid_number">
                                    <span className="counter-number">4.1</span>k
                                </h2>
                                <p className="counter-grid_text">Clients satisfaits</p>
                            </div>
                            <div className="counter-grid">
                                <h2 className="counter-grid_number">
                                    <span className="counter-number">2.9</span>k
                                </h2>
                                <p className="counter-grid_text">Flyers & logos</p>
                            </div>
                            <div className="counter-grid">
                                <h2 className="counter-grid_number">
                                    <span className="counter-number">2.1</span>k
                                </h2>
                                <p className="counter-grid_text">Sites web gérés</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="container col-lg-12 mb-50">
                    <img src="https://img.freepik.com/photos-gratuite/chef-du-departement-post-production-conseille-collegue-ameliorer-couleurs-qualite-images-images-monteurs-video-professionnels-creatifs-collaborant-afin-terminer-projet-film-dans-delais_482257-41682.jpg?t=st=1717351284~exp=1717354884~hmac=e7eb9ea31a54460f7c058bd1b71c900b0c3ace2b47ac8f7e6b61026a1d29c55c&w=1380" />{" "}
                </div> */}
            </div>
        </>
    );
}

export default MiseEnRelationPage;