import axios from 'axios';
import config from '../../config';

export const fetchDevisSuccess = (devis) => {
  return {
    type: 'FETCH_DEVIS_SUCCESS',
    payload: devis
  };
};

export const createDevisSuccess = (newDevis) => {
  return {
    type: 'CREATE_DEVIS_SUCCESS',
    payload: newDevis
  };
};

export const createDevis = (devisData) => async (dispatch) => {
  try {
    const url = config.apiUrl + '/devis';
    const response = await axios.post(url, devisData);
    if (response.status === 200) {
      dispatch(createDevisSuccess(response.data.data));
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('createDevis failed:', error);
    return false;
  }
};

export const fetchDevis = () => {
  return (dispatch) => {
    axios.get(config.apiUrl + "/devis?populate=*")
      .then((response) => {
        //console.log(response.data.data)
        dispatch(fetchDevisSuccess(response.data.data));
      })
      .catch((error) => {
        // Gérer les erreurs ici
        console.error(error);
      });
  };
};