import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Loader from "../components/Loader";
import SideMenu from "../components/SideMenu";
import Header from "../components/Header";
import Footer from "../components/Footer";

import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage"
import ServicesPage from "../pages/ServicesPage";
import JobsPage from "../pages/services/JobsPage";
import ContactPage from "../pages/ContactPage";
import ErrorPage from '../pages/ErrorPage';
import BlogPage from '../pages/BlogPage';
import TrainingsPage from '../pages/services/TrainingsPage';
import AllJobsPage from '../pages/AllJobsPage';
import DevisPage from '../pages/DevisPage';
import BiblioPage from '../pages/BiblioPage';
import FaqPage from '../pages/FaqPage';
import BlogDetailsPage from '../pages/BlogDetailsPage';
import TrainingDetailsPage from '../pages/TrainingDetailsPage';
import MiseEnRelationPage from '../pages/services/MiseEnRelationPage';
import ProspectionPage from '../pages/services/ProspectionPage';
import FormationProoPage from '../pages/services/FormationProoPage';
import LivraisonPage from '../pages/services/LivraisonPage';
import MarketingPage from '../pages/services/MarketingPage';
import ConceptionPage from '../pages/services/ConceptionPage';
import JobDetailsPage from '../pages/JobDetailsPage';


import TermesConditionsPage from '../pages/TermesConditionsPage';
import PolitiqueConfidentialitePage from '../pages/PolitiqueConfidentialitePage';
import DigitalPage from '../pages/TransformationDigitalePage';
import TransformationDigitalePage from '../pages/TransformationDigitalePage';
import CommunicationPage from '../pages/services/CommunicationPage';
import DeveloppementAppicatifPage from '../pages/services/DeveloppementAppicatifPage';
import DataAnalysePage from '../pages/services/DataAnalysePage';
import ConseilsPage from '../pages/services/ConseilsPage';

function Routers() {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const toggleSideMenu = () => {
    setIsSideMenuOpen(true);
  };

  const toggleSearch = () => {
    setIsShow(true);
  };

  const tabs = [
    {
      route: "/accueil",
      icon: "fa fa-home",
      label: "Accueil"
    }, {
      route: "/nos-services",
      icon: "fa fa-list",
      label: "Services"
    }, {
      route: "/login",
      icon: "fa fa-user",
      label: "Connexion"
    }, {
      route: "/contact",
      icon: "fa fa-phone",
      label: "Contact"
    }
  ]

  return (
    <BrowserRouter>
      <div>
        {/* <Loader /> */}
        <SideMenu isOpen={isSideMenuOpen} onClose={() => {
          setIsSideMenuOpen(false);
        }} />
      </div>
      <div>
        <Header onMenuButtonClick={toggleSideMenu} showSearch={toggleSearch} />
      </div>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/*" element={<ErrorPage />} />
        <Route exact path="/accueil" element={<HomePage />} />
        <Route exact path="/a-propos-de-nous" element={<AboutPage />} />
        <Route exact path="/nos-services" element={<ServicesPage />} />
        <Route exact path="/nos-formations" element={<TrainingsPage />} />
        <Route exact path="/nos-offres-emplois" element={<AllJobsPage />} />
        <Route exact path="/nos-actualites" element={<BlogPage />} />
        <Route exact path="/demander-un-devis" element={<DevisPage />} />
        <Route exact path="/adive-biblio" element={<BiblioPage />} />
        <Route exact path="/faq" element={<FaqPage />} />
        <Route exact path="/services/mise-en-relation" element={<MiseEnRelationPage />} />
        <Route exact path="/services/trainings" element={<TrainingsPage />} />
        <Route exact path="/contact" element={<ContactPage />} />
        <Route exact path="/termes-et-conditions" element={<TermesConditionsPage />} />
        <Route exact path="/politique-de-confidentialite" element={<PolitiqueConfidentialitePage />} />

        <Route exact path="/services/prospection" element={<ProspectionPage />} />
        <Route exact path="/services/formations-profesionnelles" element={<FormationProoPage />} />
        <Route exact path="/services/livraison" element={<LivraisonPage />} />
        <Route exact path="/services/strategie-marketing-digital" element={<MarketingPage />} />
        <Route exact path="/services/conception-graphique" element={<ConceptionPage />} />
        <Route exact path="/services/conseils" element={<ConseilsPage />} />

        <Route exact path="/digital/transformation-digitale" element={<TransformationDigitalePage />} />
        <Route exact path="/digital/strategie-marketing-digital" element={<MarketingPage />} />
        <Route exact path="/digital/strategie-communication-digitale" element={<CommunicationPage />} />
        <Route exact path="/digital/developpement-applicatif" element={<DeveloppementAppicatifPage />} />
        <Route exact path="/digital/data-analyse" element={<DataAnalysePage />} />
        <Route exact path="/digital/conception-graphique" element={<ConceptionPage />} />

        <Route exact path="/formations/:slug" element={<TrainingDetailsPage />} />
        <Route exact path="/actualite/:slug" element={<BlogDetailsPage />} />
        <Route exact path="/jobs/:slug" element={<JobDetailsPage />} />

      </Routes>
      <Footer />
      <div className="navbar fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav" role="navigation"
        style={{ backgroundColor: "white" }}>
        <div className=" d-flex flex-row justify-content-around w-100">
          {
            tabs.map((tab, index) => (
              <a href={tab.route} className="nav-link" activeClassName="active">
                <div className="row d-flex flex-column justify-content-center align-items-center">
                  <i className={tab.icon} />
                  {/* <div>{tab.label}</div> */}
                </div>
              </a>
            ))
          }
        </div>
      </div>
    </BrowserRouter>
  );
}

export default Routers;