import React from 'react';
import ReactPlayer from 'react-player';

const VideoSection = ({ titre, lien, image }) => {

    return (
        <>
            <div>
                <div className data-pos-for=".process-sec" data-sec-pos="bottom-half">
                    <div className="container">
                        <div className="video-box4" >
                            <video autoPlay loop controls style={{ borderRadius: 50 }}>
                                <source src="assets/video/video.mp4" type="video/mp4" />
                            </video>
                            {/* <div className="mb-40 video-content" style={{ backgroundColor: "bisque" }}>
                                <h2 className="h4 mb-10">{titre}</h2>
                            </div> */}
                            <div class="video-title" style={{
                                position: "absolute",
                                bottom: 20,
                                left: 20,
                                background: "rgba(0, 0, 0, 0.5)",
                                color: "white",
                                padding: 10,
                                fontSize: 24,
                                borderRadius: 5,
                                marginBottom: 50
                            }}>
                                <h2 className="mb-10" style={{ color: "white" }}>{titre}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>)
};

export default VideoSection;
