import React from "react";

export default function Testimonials(props) {

    return (
        <>
            <section className="bg-auto space" id="testiSec2" style={{ backgroundImage: "url(/assets/img/bg/testi_bg_3.png)" }}>
                <div className="container">
                    <div className="title-area text-center"><span className="sub-title">Témoignages</span>
                        <h2 className="sec-title">Les Retours De Nos Clients</h2>
                        <p className="sec-text ms-auto me-auto">Nous sommes fiers de recevoir de tels témoignages de nos clients satisfaits. Votre satisfaction est notre priorité absolue, et nous nous donnons le plaisir de fournir des services de qualité et des résultats exceptionnels à tous nos clients.
                        </p>
                    </div>
                    <div className="row slider-shadow as-carousel" id="testiSlide2" data-slide-show={2} data-lg-slide-show={2} data-md-slide-show={1} data-xs-slide-show={1} data-arrows="true">
                        <div className="col-lg-6">
                            <div className="testi-card" data-bg-src="assets/img/testimonial/testi_bg_2.svg">
                               {/*  <div className="testi-card_img"><img src="assets/img/testimonial/testi_2_1.jpg" alt="Avater" /></div> */}
                                <div className="testi-card_content">
                                    <div className="testi-card_quote"><img src="assets/img/icon/quote_left.svg" alt="icon" /></div>
                                    <p className="testi-card_text">Je tiens à exprimer ma sincère gratitude à toute l'équipe d'Adive pour la formation que vous avez dispensée. Votre expertise, votre engagement et votre capacité à rendre les concepts complexes accessibles ont grandement enrichi notre expérience d'apprentissage.

Votre passion pour le sujet et votre patience à répondre à nos questions ont été très appréciées. Grâce à vous, nous avons acquis des compétences précieuses qui auront un impact significatif dans notre domaine.

Encore une fois, un grand merci pour cette formation exceptionnelle. Nous sommes chanceux d'avoir eu l'opportunité de bénéficier de vos connaissances.

</p>
                                    <h3 className="testi-card_name">T. Marie Elie OUEDRAOGO</h3><span className="testi-card_desig">Directeur Général Adjoint de Biograine SA</span>
                                    <div className="testi-card_review"><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="testi-card" data-bg-src="assets/img/testimonial/testi_bg_2.svg">
                               {/*  <div className="testi-card_img"><img src="assets/img/testimonial/testi_2_2.jpg" alt="Avater" /></div> */}
                                <div className="testi-card_content">
                                    <div className="testi-card_quote"><img src="assets/img/icon/quote_left.svg" alt="icon" /></div>
                                    <p className="testi-card_text">Toute ma reconnaissance à Adive pour l'exceptionnelle exécution de la stratégie digitale. Votre expertise, votre créativité et votre capacité à anticiper les besoins ont été des atouts précieux pour notre projet.

Votre approche stratégique a permis d'atteindre nos objectifs de manière efficace et de maximiser l'impact de notre présence en ligne. Votre travail a eu un réel impact sur notre visibilité et notre engagement avec notre audience.

Je tiens à souligner la rigueur et le professionnalisme dont vous avez fait preuve tout au long du processus. Vous avez su relever les défis avec brio et nous sommes extrêmement satisfaits des résultats obtenus.

Encore une fois, un grand merci pour cette stratégie digitale exceptionnelle. Nous avons eu la chance de collaborer avec vous.
</p>
                                    <h3 className="testi-card_name">SOULAMA Yaya</h3><span className="testi-card_desig">Directeur Général de ADESER</span>
                                    <div className="testi-card_review"><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
