import React, { useEffect } from 'react';
import { fetchJobs } from '../../redux/actions/jobsActions';
import Services from "../../components/Services";
import Breadcumb from '../../components/Breadcumb';
import Pricing from '../../components/Pricing';
import DemandeEmploi from '../../components/DemandeEmploi';
import VideoSection from '../../components/VideoSection';

const ConseilsPage = (props) => {
    const breadcumb = {
        text: "Conseils et études de marché ",
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "Conseils et études de marché "
    }

    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />
                <div>
                    <div className="position-relative mb-50" style={{ backgroundColor: "aliceblue" }}>
                        <div className="row gx-0">
                            <div className="col-xl-auto">
                                <div className="video-box3" data-overlay="overlay1" data-opacity={5}>
                                    <img src="https://www.dynamique-mag.com/wp-content/uploads/3051c47401157d91186d55caaaa4ec01.jpg" alt="video" />{" "}
                                </div>
                            </div>
                            <div className="col-xl-auto">
                                <div className="why-box">
                                    <div className="title-area mb-30">
                                        <span className="sub-title text-white">Notre service</span>
                                        <h3 className="sec-title text-white">
                                            Conseils et Etudes de marché
                                        </h3>
                                    </div>
                                    <p className="mt-n2 mb-30 text-white">

                                    </p>
                                    <div className="info-media-wrap">
                                        <div className="info-media">
                                            <div className="info-media_icon">
                                                <i className="far fa-lightbulb-on" />
                                            </div>
                                            <div className="media-body">
                                                <h3 className="h6 info-media_title">Pourquoi réaliser une étude de marché ?</h3>
                                                <p className="info-media_text">
                                                    La réalisation d'une étude de marché vise à analyser un projet de développement concernant une activité, un produit ou un service existant, mais aussi à évaluer une nouvelle activité ou le lancement d'un nouveau produit ou service.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="info-media">
                                            <div className="info-media_icon">
                                                <i className="far fa-circle-dollar" />
                                            </div>
                                            <div className="media-body">
                                                <h3 className="h6 info-media_title">Pourquoi nous confier votre étude de marché ?</h3>
                                                <p className="info-media_text">
                                                    Parce que nous travaillons vite et bien, avec comme point de départ une compréhension fine de votre besoin.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="counter-grid-wrap">
                            <div className="counter-grid">
                                <a href="/contact" className="as-btn" style={{ backgroundColor: 'gray' }}>
                                    Contactez-nous
                                </a>
                            </div>
                            <div className="counter-grid">
                                <a href="/demander-un-devis" className="as-btn" style={{ backgroundColor: 'gray' }}>
                                    demander un devis
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-lg-12">
                                <div className="page-single">
                                    <div className="page-content">
                                        <h2 className="h3 page-title">Conseils et Etudes de marché  </h2>
                                        <h4 className="h4"></h4>
                                        <p>Les principes fondamentaux d'une étude de marché chez ADIVE consistent à exploiter les ressources du web tout en interrogeant activement vos clients, fournisseurs et concurrents.</p>
                                        <p>Nos analyses de marché se concentrent sur les données factuelles et chiffrées, en explorant divers aspects tels que le contexte, la chaîne de valeur, la demande, les moteurs et les facteurs sous-jacents, l'offre et la concurrence, ainsi que les facteurs de perturbation. Nous synthétisons ensuite ces informations pour fournir des conseils en stratégie, en positionnement cible et en facteurs de succès.</p>
                                        <p>Pour mener à bien ces missions, ADIVE mobilise :</p>
                                        <div className="list-column">
                                            <div className="ticklist">
                                                <ul>
                                                    <li>Un réseau d'experts pour établir les contacts nécessaires, </li>
                                                    <li>Des profils expérimentés, flexibles et agiles, </li>
                                                    <li>Des méthodes d'analyse stratégique éprouvées, </li>
                                                    <li>L'utilisation systématique des données sur internet : webscraping, recherche documentaire, tendances, réseaux sociaux, analyse des sites web, etc. </li>
                                                    <li>Si nécessaire, nos partenaires pour la réalisation d'études quantitatives et qualitatives.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <h4 className="h4" style={{ marginTop: 20 }}>Nos études de marché n’ont pas de limite, c’est en fonction de vos besoins.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </section >
            </div>
        </>
    );
}

export default ConseilsPage;