import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const BlogHomeCard = (props) => {

    const { blog } = props;
    const dateStr = blog?.attributes?.publishedAt;
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth();
    const monthNames = [
        'Jan', 'Fev', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'
    ];
    const monthName = monthNames[month];
    return (
        <>
            <div className="col-md-4">
                <div className="blog-box">
                    <div className="blog-img">
                        <Link to={`/actualite/${blog?.attributes.slug}`}>
                            <img
                                src={blog?.attributes?.image.data.attributes.url}
                                style={{ width: '100%', height: '200px' }}
                                alt="Blog Image"
                            /></Link>
                    </div>
                    <div className="blog-content">
                        <Link to={`/actualite/${blog?.attributes.slug}`} className="date">
                            <span className="gr-text">{day}</span> {monthName}
                        </Link>
                        <div className="blog-meta">
                            <Link to={`/actualite/${blog?.attributes.slug}`}>
                                <i className="far fa-tag" />
                                {blog?.attributes?.tags ? blog?.attributes?.tags?.data[0]?.attributes?.nom : '-'}
                            </Link>
                            {/* <a href="blog.html" tabIndex={-1}>
                                <i className="fal fa-comment" />
                                Comments (3)
                            </a> */}
                        </div>
                        <h3 className="blog-title box-title">
                            <Link className="text-inherit" to={`/actualite/${blog?.attributes?.slug}`}> {blog?.attributes?.titre}</Link>
                        </h3>
                        <p className="blog-text">
                            {blog?.attributes?.contenu.slice(0, 120)}...
                        </p>
                        <Link style={{ cursor: "pointer" }} className="gr-link" to={`/actualite/${blog?.attributes?.slug}`}> Lire Plus</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogHomeCard;
