import React, { useEffect } from 'react';
import Breadcumb from '../components/Breadcumb';

const TransformationDigitalePage = (props) => {
    const breadcumb = {
        text: "Transformation digitale",
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "Transformation digitale"
    }

    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />
                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-lg-12">
                                <div className="page-single">
                                    <img src="https://2si.fr/wp-content/uploads/2018/01/transfo_numerique.jpg" style={{width: '100%'}} />
                                    <h2 className="h3 page-title">1. C’est quoi la transformation digitale</h2>
                                    <div className="page-content">
                                        <h4 className="h4"> </h4>
                                        <p>La transition numérique implique l'incorporation de la technologie digitale à tous les aspects d'une entreprise, transformant profondément ses processus opérationnels et sa proposition de valeur pour les clients. Elle engendre également un changement culturel, incitant les organisations à remettre en question constamment leurs pratiques habituelles, à expérimenter et à apprendre de leurs échecs.</p>
                                        <h4 className="h4"></h4>
                                        <p>
                                            La première étape de la transformation numérique doit être de définir clairement le problème à résoudre, une opportunité évidente ou un objectif ambitieux, comme l'a récemment souligné Jay Ferro, directeur de l'information et de la technologie chez Clario. Selon Ferro, le "pourquoi" de la transformation numérique de votre organisation peut consister à améliorer l'expérience client, à réduire les frictions, à accroître la productivité ou à augmenter la rentabilité. Alternativement, si l'objectif est ambitieux, il pourrait s'agir de devenir le meilleur partenaire commercial possible en exploitant des technologies numériques qui n'étaient pas disponibles il y a quelques années.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-lg-12">
                                <div className="page-single">
                                    <div className="page-content">
                                        <h2 className="h3 page-title">2. La transformation numérique est-elle importante ?</h2>
                                        <h4 className="h4"></h4>
                                        <p>Une entreprise peut entreprendre une transformation numérique pour diverses raisons, mais la principale motivation est souvent la nécessité : c'est une question de survie. Suite à la pandémie de COVID-19, la capacité d'une organisation à s'adapter rapidement aux perturbations de la chaîne d'approvisionnement, aux pressions des délais de mise sur le marché et aux évolutions rapides des attentes des clients est devenue essentielle.</p>
                                        <p>Lors d'un événement de la série MIT Sloan CIO Symposium, les responsables informatiques ont convenu que le comportement des consommateurs a rapidement évolué à de nombreux égards depuis le début de la pandémie. Sandy Pentland, professeur au MIT Media Lab, a décrit comment les systèmes automatisés optimisés, notamment dans le domaine de la gestion de la chaîne d'approvisionnement, ont été mis à rude épreuve face à des changements rapides dans la demande et l'offre – une réalité à laquelle presque tout le monde a été confronté personnellement pendant la pandémie.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </section >
            </div >
        </>
    );
}

export default TransformationDigitalePage;