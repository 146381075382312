import React from 'react';

const ServicesHome = ({ titre, lien, image }) => {

    return (
        <>
            <div>
                <section className="bg-smoke2 space">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-xl-6 align-self-center">
                                <div className="title-area mb-30 text-center text-xl-start">
                                    <span className="sub-title">CE QUE NOUS FAISONS</span>
                                    <h2 className="sec-title">Nos Services</h2>
                                    <p className="sec-text me-auto ms-auto ms-xl-0 pe-xl-5 me-xl-5">
                                        En tant qu'académie du digital et de la
                                        vente, nous proposons une gamme complète de services pour aider votre
                                        entreprise à prospérer dans le monde numérique. Voici un aperçu de nos
                                        principaux services.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="service-card">
                                    <div className="service-card_icon"><i className="fal fa-display-code" /></div>
                                    <h3 className="service-card_title"><a href="/services/jobs">Mise en relation emploi</a></h3>
                                    <p className="service-card_text">Nous proposons un service de mise en relation pour l'emploi</p>
                                    <a href="#" className="service-card_btn">En Savoir Plus</a>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="service-card">
                                    <div className="service-card_icon"><i className="fal fa-chart-line-up" /></div>
                                    <h3 className="service-card_title"><a href="/services/trainings">Formation professionnelle</a></h3>
                                    <p className="service-card_text">Nous proposons un service de formation professionnelle</p>
                                    <a href="#" className="service-card_btn">En Savoir Plus</a>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="service-card">
                                    <div className="service-card_icon"><i className="fal fa-layer-group" /></div>
                                    <h3 className="service-card_title"><a href="#">Service de livraison</a></h3>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="service-card">
                                    <div className="service-card_icon"><i className="fal fa-bezier-curve" /></div>
                                    <h3 className="service-card_title"><a href="#">Prospection commerciale</a></h3>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="service-card">
                                    <div className="service-card_icon"><i className="fal fa-chart-pie-simple" /></div>
                                    <h3 className="service-card_title"><a href="#">Stratégie de marketing </a>
                                    </h3>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                                <a
                                    href="/nos-services"
                                    className="service-card more background-image"
                                    style={{
                                        //backgroundImage: 'url("https://angfuzsoft.com/html/dalky/demo/assets/img/bg/service_card_bg.jpg")'
                                        backgroundColor: "gray"
                                    }}
                                >
                                    <h3 className="service-card_title">
                                        3+
                                        <br />
                                        Voir tout
                                    </h3>
                                    <i className="far fa-long-arrow-right icon-btn" />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        </>)
};

export default ServicesHome;
