import React, { useState } from "react";
import LanguageSwitcher from "./LanguageSwitcher";
import config from "../config";
import { useLocation } from 'react-router-dom';

const MenuItems = ({ menu }) => {
    //    localStorage.setItem('activeItem', '/accueil');

    const location = useLocation();
    const currentURL = location.pathname;
    const parts = currentURL.split('/');
    //const extractedString = '/' + parts[1];
    if (parts.length > 2) {
        if (parts.includes("jobs")) {
            localStorage.setItem('activeItem', '/nos-offres-emplois');
        } else if (parts.includes("formations")) {
            localStorage.setItem('activeItem', '/nos-formations');
        } else if (parts.includes("actualite")) {
            localStorage.setItem('activeItem', '/nos-actualites');
        } else if (parts.includes("digital")) {
            localStorage.setItem('activeItem', '#');
        } else {
            localStorage.setItem('activeItem', '/nos-services');
        }
    }

    else
        localStorage.setItem('activeItem', currentURL);
    const activeItem = localStorage.getItem('activeItem');
    const handleItemClick = (route, item) => {
        // alert(item);
        localStorage.setItem('activeItem', item);
        window.location.href = route;
        // setActiveItem(item);
        //alert(localStorage.getItem('activeItem'));
    };

    return (
        <>
            <ul>
                {
                    menu.length && menu.map((menuItem, i) =>
                        menuItem.hasChildren ? <li key={i} className={'menu-item-has-children ' + (activeItem === menuItem.route ? 'menu-active' : '')} ><a style={{ cursor: "pointer" }} onClick={() => handleItemClick(menuItem.route, menuItem.route)}>{menuItem.name}</a>
                            <ul className="sub-menu">
                                {
                                    menuItem.children && menuItem.children.map((child, f) =>
                                        <li key={f}><a style={{ cursor: "pointer", textTransform: "unset" }} onClick={() => handleItemClick(child.route, menuItem.route)}>{child.name}</a></li>
                                    )
                                }
                            </ul>
                        </li> : <li key={i} className={activeItem === menuItem.route ? 'menu-active' : ''}><a style={{ cursor: "pointer" }} onClick={() => handleItemClick(menuItem.route, menuItem.route)}>{menuItem.name}</a></li>
                    )}
            </ul >
        </>
    );
}

export default MenuItems;
