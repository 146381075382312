const Pagination = ({ titre }) => {
    return (
        <>
            <div className="text-center mt-5">
                <div className="as-pagination">
                    <ul>
                        <li><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#"><i className="far fa-arrow-right" /></a></li>
                    </ul>
                </div>
            </div>
        </>
    );
}


export default Pagination;
