import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchRecentBlogs } from '../redux/actions/recentBlogsActions';
import { Link } from 'react-router-dom';
import config from "../config";
import SubscribeNewsLetter from './SubscribeNewsLetter';

const Footer = (props) => {

    const { recent_blogs, fetchRecentBlogs, titre } = props;

    useEffect(() => {
        fetchRecentBlogs();
    }, [fetchRecentBlogs]);

    return (
        <>
            <div>
                <div className="" data-pos-for=".footer-wrapper"
                    data-sec-pos="bottom-half"
                    style={{ marginBottom: "-97px" }}
                ><div className="container">
                        <SubscribeNewsLetter titre={'Abonnez-vous gratuitement à notre newsletter pour recevoir les dernières informations.'} />
                    </div>
                </div>
                <footer className="footer-wrapper footer-layout2" data-bg-src="/assets/img/bg/footer_bg_4.jpg" style={{ paddingTop: "97px" }}>
                    <div className="widget-area">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-md-6 col-xl-4">
                                    <div className="widget footer-widget">
                                        <h3 className="widget_title">A Propos De Nous</h3>
                                        <div className="as-widget-about">
                                            <p className="about-text">Nous sommes une académie passionnée et créative, prête à vous accompagner dans votre parcours numérique et à vous aider à développer votre entreprise.</p>
                                            <p className="footer-info style2"><i className="fas fa-location-dot" /> Ouagadougou, Burkina Faso</p>
                                            <p className="footer-info style2"><i className="fas fa-envelope" /> <a href={`mailto:${config.email}`} className="info-box_link">{config.email}</a></p>
                                            <p className="footer-info style2"><i className="fas fa-phone" /> <a href={`tel:${config.firstContact}`} className="info-box_link">{config.firstContact}</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-auto">
                                    <div className="widget widget_nav_menu footer-widget">
                                        <h3 className="widget_title">Liens Rapides</h3>
                                        <div className="menu-all-pages-container">
                                            <ul className="menu">
                                                <li><a href="/">Accueil</a></li>
                                                <li><a href="/a-propos-de-nous">A Propos De Nous</a></li>
                                                <li><a href="/nos-actualites">Actualités</a></li>
                                                <li><a href="/demander-un-devis">Devis</a></li>
                                                <li><a href="/faq">Faq</a></li>
                                                <li><a href="/contact">Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-auto">
                                    <div className="widget widget_nav_menu footer-widget">
                                        <h3 className="widget_title">Services</h3>
                                        <div className="menu-all-pages-container">
                                            <ul className="menu">
                                                <li><a href="/nos-offres-emplois">Emplois</a></li>
                                                <li><a href="/services/trainings">Nos formations</a></li>
                                                <li><a href="/services/livraison">Service de livraison</a></li>
                                                <li><a href="/services/prospection">Mise à disposition d’équipes</a></li>
                                                <li><a href="/services/strategie-marketing-digital">Stratégie de marketing digital</a></li>
                                                <li><a href="/services/conception-graphique">Conception Graphique & Web</a></li>
                                                <li><a href="/adive-biblio">Espace vente en ligne</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-auto">
                                    <div className="widget footer-widget">
                                        <h3 className="widget_title">Postes récents </h3>
                                        <div className="recent-post-wrap">
                                            {
                                                recent_blogs && recent_blogs.map((recent_blog, i) =>
                                                    <div className="recent-post" key={i}>
                                                        <div className="media-img">
                                                            <Link to={`/actualite/${recent_blog?.attributes?.slug}`}>
                                                                <img
                                                                    src={recent_blog?.attributes?.image?.data.attributes.url}
                                                                    style={{ width: '100%', height: '60px' }}
                                                                    alt="Blog Image"
                                                                />
                                                                </Link>
                                                        </div>
                                                        <div className="media-body">
                                                            <h4 className="post-title">
                                                                <Link className="text-inherit" to={`/actualite/${recent_blog?.attributes.slug}`}>  {recent_blog?.attributes?.titre.slice(0, 50)}...</Link>
                                                            </h4>
                                                            <div className="recent-post-meta">
                                                                <Link to={`/actualite/${recent_blog?.attributes?.slug}`} className="#">
                                                                    <i className="fal fa-calendar-days" />
                                                                    {recent_blog?.attributes?.publishedAt}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-wrap">
                        <div className="container">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6">
                                    <p className="copyright-text">Copyright
                                        <i className="fal fa-copyright" /> 2023 <a href="#">Adive</a>.
                                        Tous Droits Réservés.
                                    </p>
                                </div>
                                <div className="col-lg-6 text-end d-none d-lg-block">
                                    <div className="footer-links">
                                        <ul>
                                            <li><a href="/politique-de-confidentialite">Politique de confidentialité</a></li>
                                            <li><a href="/termes-et-conditions">Conditions d'utilisation</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <a href="#" className="scrollToTop scroll-btn">
                    <i className="far fa-long-arrow-up"></i>
                </a>
            </div>
        </>
    );

};

const mapStateToProps = (state) => {
    return {
        recent_blogs: state.recent_blogs.recent_blogs,
        loading: state.recent_blogs.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchRecentBlogs: () => dispatch(fetchRecentBlogs())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
