import React, { useEffect } from 'react';
import { fetchJobs } from '../../redux/actions/jobsActions';
import Services from "../../components/Services";
import Breadcumb from '../../components/Breadcumb';
import Pricing from '../../components/Pricing';
import DemandeEmploi from '../../components/DemandeEmploi';
import VideoSection from '../../components/VideoSection';

const CommunicationPage = (props) => {
    const breadcumb = {
        text: "Stratégie de communication digitale",
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "Stratégie de communication digitale"
    }

    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />

                <div className="overflow-hidden space">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-7 pe-xxl-5">
                                <div className="img-box3">
                                    <div className="img1">
                                        <img src="https://www.keacrea.com/images/pages/facebook/avantage-reseaux-sociaux.png" alt="About" />
                                    </div>
                                    <div className="shape1">
                                        <img src="../assets/img/shape/about_shape_1.svg" alt="shape" />
                                    </div>
                                    {/* <div className="about-counter1">
                                        <h3 className="counter-title">
                                            <span className="counter-number">24</span>+
                                        </h3>
                                        <span className="counter-text">Years Experience</span>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <div className="title-area mb-30">
                                    <span className="sub-title">
                                        Communication Digitale
                                        <span className="shape right">
                                            <span className="dots" />
                                        </span>
                                    </span>
                                    <h3 className="sec-title">La stratégie adéquate pour votre business</h3>
                                </div>
                                <p className="mt-n2 mb-4">
                                    {/* All technology is perfect for the best business solutions &amp; we
                                    offer help to achieve your goals. */}
                                </p>
                                <div className="checklist style2">
                                    <ul>
                                        <li>Analyse de la stratégie existante et apport des améliorations</li>
                                        <li>Analyse des besoins et objectifs pour la mise en place d’une stratégie toute neuve de communication digitale</li>
                                        <li>Suivi et contrôle des indicateurs de performance et ajustement de la stratégie</li>
                                    </ul>
                                </div>
                                <div className="btn-wrap mt-30">
                                    <a href="/demander-un-devis" className="as-btn">
                                        demander un devis
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-lg-12">
                                <div className="page-single">
                                    <div className="page-content">
                                        <h2 className="h3 page-title">Stratégie de Communication digitale </h2>
                                        <h4 className="h4"></h4>
                                        <p>Notre objectif à travers la communication digitale est de vous permettre d'attirer du trafic sur vos sites web et d'améliorer leur positionnement dans les moteurs de recherche afin de gagner en visibilité. Pour ce faire, deux stratégies se révèlent particulièrement efficaces : le référencement naturel (SEO) et le référencement payant (SEA).</p>
                                        <h4 className="h4">La création de sites internet </h4>
                                        <p>C’est une activité fondamentale pour les entreprise, marquant souvent le début d'une stratégie numérique. Avec près de 2 milliards de sites web dans le monde, ces plateformes jouent un rôle crucial en tant que vitrine, adresse en ligne et outil de développement commercial pour les entreprises. Elles se déclinent généralement en trois types : les sites vitrines pour valoriser une marque, les blogs pour la création de contenus, et les sites marchands pour la vente en ligne</p>
                                        <h4 className="h4">L’e-mailing et la newsletter </h4>
                                        <p>L'e-mailing demeure un canal de conversion majeur, permettant d'informer, de promouvoir et de personnaliser le contenu pour vos clients. L'automation marketing, quant à elle, consiste à scénariser des actions marketing pour développer la valeur client au fil du temps, en se basant sur des données recueillies. La newsletter vous permettre de communiquer d’informer permanemment aux clients sur vos nouveautés ou vos promotion en cours</p>
                                        <h4 className="h4">Community management  </h4>
                                        <p>Le community management englobe la création et la gestion de vos pages sur les réseaux sociaux(Facebook, Instagram, LinkedIn, YouTube…) et les campagnes d'influence exploitent la popularité des influenceurs pour promouvoir vos produits.</p>
                                        <h4 className="h4">Conception graphique et de contenu web  </h4>
                                        <div className="list-column">
                                            <div className="ticklist">
                                                <ul>
                                                    <li>Conception et Impression </li>
                                                    <li>Rédaction de contenu web </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <h4 className="h4">Le SEO (référencement naturel) et le SEA (référencement payant)</h4>
                                        <p>Le Search Engine Optimization est une technique qui vise à améliorer le référencement naturel des sites sur les moteurs de recherche, tandis que la publicité digitale (SEA) offre des supports variés pour des annonces ciblées. </p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </section >
            </div>
        </>
    );
}

export default CommunicationPage;