import { combineReducers } from 'redux';
import { jobsReducer } from "./reducers/jobsReducer";
import { blogsReducer } from "./reducers/blogsReducer"
import { trainingsReducer } from "./reducers/trainingsReducer"
import { faqsReducer } from "./reducers/faqsReducer"
import { categoriesReducer } from "./reducers/categoriesReducer"
import { tagsReducer } from "./reducers/tagsReducer"
import { recentBlogsReducer } from "./reducers/recentBlogsReducer"
import { paymentsReducer } from './reducers/paymentsReducer';
import { devisReducer } from './reducers/devisReducer';
import { bibliosReducer } from './reducers/bibliosReducer';
import { newslettersReducer } from './reducers/newslettersReducer';



const rootReducer = combineReducers({
  jobs: jobsReducer,
  blogs: blogsReducer,
  biblios: bibliosReducer,
  devis: devisReducer,
  trainings: trainingsReducer,
  faqs: faqsReducer,
  categories: categoriesReducer,
  tags: tagsReducer,
  recent_blogs: recentBlogsReducer,
  payments: paymentsReducer,
  newsletters: newslettersReducer
});

export default rootReducer;