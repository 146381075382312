import React from "react";
import config from "../config";
import Breadcumb from '../components/Breadcumb';
import Testimonials from '../components/HomePage/Testimonials';

export default function ServicesPage(props) {
    const breadcumb = {
        text: "Tous nos services",
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "Nos Services"
    }

    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />
                <section className="space">
                    <div className="container">
                        <div className="title-area text-center"><span className="sub-title">Ce que nous faisons</span>
                            <h2 className="sec-title">Nos Différents Services</h2>
                            <p className="sec-text ms-auto me-auto">Nous proposons une gamme variée de services pour aider votre entreprise ou votre business à prospérer dans le monde numérique. Voici un aperçu de nos principaux services.</p>
                        </div>
                        <div className="row gy-4">
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="service-card">
                                    <div className="service-card_icon"><i className="fal fa-display-code" /></div>
                                    <h3 className="service-card_title"><a href="/services/jobs">Mise en relation emploi</a></h3>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="service-card">
                                    <div className="service-card_icon"><i className="fal fa-chart-line-up" /></div>
                                    <h3 className="service-card_title"><a href="/services/trainings">Formation professionnelle</a></h3>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="service-card">
                                    <div className="service-card_icon"><i className="fal fa-layer-group" /></div>
                                    <h3 className="service-card_title"><a href="/services/livraison">Service de livraison</a></h3>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="service-card">
                                    <div className="service-card_icon"><i className="fal fa-bezier-curve" /></div>
                                    <h3 className="service-card_title"><a href="/services/prospection">Prospection commerciale</a></h3>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="service-card">
                                    <div className="service-card_icon"><i className="fal fa-chart-pie-simple" /></div>
                                    <h3 className="service-card_title"><a href="/services/strategie-marketing-digitale">Stratégie de marketing </a>
                                    </h3>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="service-card">
                                    <div className="service-card_icon"><i className="fal fa-bullhorn" /></div>
                                    <h3 className="service-card_title"><a href="/services/conception-graphique">Rédaction de contenu web</a></h3>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="service-card">
                                    <div className="service-card_icon"><i className="fal fa-file-pen" /></div>
                                    <h3 className="service-card_title"><a href="/services/conception-graphique">Conception graphique</a></h3>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                                <div className="service-card">
                                    <div className="service-card_icon"><i className="fal fa-chart-mixed" /></div>
                                    <h3 className="service-card_title"><a href="/adive-biblio">Vente en ligne</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="space process-sec bg-smoke" style={{ backgroundImage: "url(assets/img/bg/process_bg_1.png)" }}>
                    <div className="container">
                        <div className="col-lg-6">
                            <div className="title-area text-center text-lg-start"><span className="sub-title">Comment nous travaillons</span>
                                <h2 className="sec-title">Notre processus de travail</h2>
                                <p className="sec-text mb-4 me-auto ms-auto ms-lg-0 pe-lg-5 me-lg-5">Nous travaillons en suivant un processus en quatre (04)  étapes pour garantir un travail efficace et des résultats de qualité. Voici comment nous procédons :</p><a href="/a-propos-de-nous" className="as-btn mb-20">En Savoir Plus</a>
                            </div>
                        </div>
                        {/* <div className="process-line"><img src="assets/img/shape/process_line.svg" alt="shape" /></div> */}
                        <div className="row gy-30">
                            <div className="col-md-6 col-lg-3 process-card-wrap">
                                <div className="process-card">
                                    <div className="process-card_icon"><img src="assets/img/icon/process_1_1.svg" alt="icon" /></div>
                                    <div className="process-card_number">1</div>
                                    <h2 className="process-card_title box-title">Analyse et compréhension des besoins</h2>
                                    <p className="process-card_text">Dans cette première étape, nous prenons le temps d'analyser et
                                        de comprendre vos besoins spécifiques. Nous écoutons attentivement vos objectifs, vos exigences et
                                        vos contraintes.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 process-card-wrap">
                                <div className="process-card">
                                    <div className="process-card_icon"><img src="assets/img/icon/process_1_2.svg" alt="icon" /></div>
                                    <div className="process-card_number">2</div>
                                    <h2 className="process-card_title box-title">Planification et conception</h2>
                                    <p className="process-card_text">Une fois que nous avons une bonne compréhension de vos besoins, nous passons à
                                        la planification et à la conception. Nous élaborons un plan détaillé qui inclut les différentes étapes du projet,
                                        les délais, les ressources nécessaires et les responsabilités. </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 process-card-wrap">
                                <div className="process-card">
                                    <div className="process-card_icon"><img src="assets/img/icon/process_1_3.svg" alt="icon" /></div>
                                    <div className="process-card_number">3</div>
                                    <h2 className="process-card_title box-title">Développement et mise en œuvre</h2>
                                    <p className="process-card_text">Une fois que la planification et la conception sont finalisées, nous passons à la phase
                                        de développement et de mise en œuvre. Notre équipe de développeurs met en place les fonctionnalités, les interfaces et
                                        les éléments nécessaires pour concrétiser le projet. </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 process-card-wrap" style={{ marginTop: -400 }}>
                                <div className="process-card">
                                    <div className="process-card_icon"><img src="assets/img/icon/arrow_down.svg" alt="icon" /></div>
                                    <div className="process-card_number">4</div>
                                    <h2 className="process-card_title box-title">Evaluation des réalisations </h2>
                                    <p className="process-card_text">Evaluation des réalisations pour une amélioration continue. </p>
                                </div>
                            </div>
                        </div>
                        <div className="process-call-wrap">
                            <div className="process-call-btn">
                                <div className="play-btn"><i className="fa-solid fa-phone" /></div><a href={`tel:${config.firstContact}`} className="call-link" style={{marginLeft: 20}}>{config.firstContact}</a>
                            </div>
                            <div className="process-call-img"><img src="https://png.pngtree.com/png-vector/20230901/ourmid/pngtree-an-animated-black-woman-avatar-on-a-white-background-vector-png-image_7023437.png" alt="man" /></div>
                        </div>
                    </div>
                </section>
                <section className="space" style={{ backgroundImage: "url(assets/img/bg/cta_bg_1.png)", backgroundColor: "gray" }} data-overlay="title" data-opacity={9}>
                    <div className="container z-index-common text-center">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-10">
                                <div className="title-area text-center mb-40"><span className="sub-title text-white">Besoin d'aide ?</span>
                                    <h2 className="sec-title text-white">Vous avez besoin de notre aide?</h2>
                                    <p className="sec-text text-white ms-auto me-auto">Ne vous inquiétez pas, nous sommes là pour vous aider ! Notre équipe dévouée est prête à répondre à toutes vos
                                        interrogations et à vous fournir le soutien dont vous avez besoin.</p>
                                </div>
                                <div className="btn-group justify-content-center"><a href="/contact" className="as-btn style3">Contactez-Nous</a></div>
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonials />
            </div>
        </>
    );
}
