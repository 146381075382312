import React from "react";

export default function ErrorPage(props) {

    return (
        <>

            <div>

                <section className="space">
                    <div className="container">
                        <div className="edu-error-area eduvibe-404-page edu-error-style">
                            <div className="container eduvibe-animated-shape">
                                <div className="row g-5">
                                    <div className="col-lg-12">
                                        <div className="content text-center">
                                            <div className="thumbnail mb--60">
                                                <img src="https://eduvibe.devsvibe.com/main/wp-content/themes/eduvibe/assets/images/404.png" alt="404 Images" />
                                            </div>
                                            <div className="error-content">
                                                <h2 className="error-title">Opp’s ! Cette page est introuvable</h2>
                                                <p className="error-text">Il semble que rien n'ait été trouvé à cet endroit. Essayez peut-être l'un des liens ci-dessous
                                                    ou une recherche ?</p>
                                            </div>
                                            <div className="backto-home-btn">
                                                <a className="edu-btn" href="/">
                                                    Accueil
                                                    <i className="fal fa-arrow-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                    <div className="shape-image shape-image-1">
                                        <img src="https://eduvibe.devsvibe.com/main/wp-content/themes/eduvibe/assets/images/shapes/shape-11-06.png" alt="Shape Thumb" />
                                    </div>
                                    <div className="shape-image shape-image-2">
                                        <img src="https://eduvibe.devsvibe.com/main/wp-content/themes/eduvibe/assets/images/shapes/shape-09-02.png" alt="Shape Thumb" />
                                    </div>
                                    <div className="shape-image shape-image-3">
                                        <img src="https://eduvibe.devsvibe.com/main/wp-content/themes/eduvibe/assets/images/shapes/shape-05-06.png" alt="Shape Thumb" />
                                    </div>
                                    <div className="shape-image shape-image-4">
                                        <img src="https://eduvibe.devsvibe.com/main/wp-content/themes/eduvibe/assets/images/shapes/shape-14-03.png" alt="Shape Thumb" />
                                    </div>
                                    <div className="shape-image shape-image-5">
                                        <img src="https://eduvibe.devsvibe.com/main/wp-content/themes/eduvibe/assets/images/shapes/shape-03-10.png" alt="Shape Thumb" />
                                    </div>
                                    <div className="shape-image shape-image-6">
                                        <img src="https://eduvibe.devsvibe.com/main/wp-content/themes/eduvibe/assets/images/shapes/shape-15.png" alt="Shape Thumb" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    );
}
