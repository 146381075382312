import React, { useEffect } from 'react';
import { fetchJobs } from '../../redux/actions/jobsActions';
import Services from "../../components/Services";
import Breadcumb from '../../components/Breadcumb';
import Pricing from '../../components/Pricing';
import DemandeEmploi from '../../components/DemandeEmploi';
import VideoSection from '../../components/VideoSection';

const MiseEnRelationPage = (props) => {
    const breadcumb = {
        text: "Mise en rélation emploi",
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "Mise en relation emploi"
    }

    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />
                <section className="space-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-lg-12">
                                <div className="page-single">
                                    <div className="page-img" style={{ width: "100%" }}>
                                        <img src="https://omondecanada.com/refonteweb/wp-content/uploads/2022/03/omonde-equipe-scaled.jpg" alt="Service Image" />
                                    </div>
                                    <div className="page-content">
                                        <h2 className="h3 page-title">Mise En Rélation Emploi</h2>
                                        <p>Nous proposons un service de mise en relation pour l'emploi, qui vise à connecter les chercheurs d'emploi avec les
                                            opportunités professionnelles correspondantes. Notre plateforme facilite le processus de recherche d'emploi en offrant
                                            un espace centralisé où les candidats peuvent explorer les offres d'emploi et les entreprises peuvent publier leurs annonces.</p>
                                        <p className="mb-30">Notre objectif est de simplifier et d'accélérer le processus de recherche d'emploi
                                            en mettant en relation les talents et les opportunités professionnelles. Nous nous efforçons de
                                            fournir un environnement convivial et efficace pour favoriser les rencontres fructueuses entre les
                                            employeurs et les candidats.</p>
                                        <div className="row">
                                            <div className="col-xl-6 mb-30"><img className="rounded-3" src="https://www.safarifinances.com/files/images/Business_connection160719164202.jpg" alt="service" /></div>
                                            <div className="col-xl-6 mb-30">
                                                <h4 className="h4 mt-n2">Fonctionnement</h4>
                                                <div className="checklist">
                                                    <ul>
                                                        <li>Création de cv pro</li>
                                                        <li>Création de votre profil</li>
                                                        <li>Recherche d'offres d'emploi</li>
                                                        <li>Candidature </li>
                                                        <li>Évaluation des candidatures</li>
                                                        <li>Entretiens </li>
                                                        <li>Sélection finale et embauche</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Pricing />
                            <DemandeEmploi />
                            {/*  <VideoSection titre={"Présentation Du service"}  /> */}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default MiseEnRelationPage;