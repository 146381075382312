import React, { useEffect } from 'react';
import { fetchJobs } from '../../redux/actions/jobsActions';
import Services from "../../components/Services";
import Breadcumb from '../../components/Breadcumb';
import Pricing from '../../components/Pricing';
import DemandeEmploi from '../../components/DemandeEmploi';
import VideoSection from '../../components/VideoSection';

const ProspectionPage = (props) => {
    const breadcumb = {
        text: "Mise à disposition d’équipes",
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "Mise à disposition d’équipes"
    }

    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />
                <div className="space" id="about-sec">
                    <div className="container">
                        <div className="row">
                            <img src="https://www.acceor.com/wp-content/uploads/2022/11/employe-presentant-la-prospection-commerciale-a-un-prospect.jpeg" />

                            <p className='mt-50'>
                                <span className="h4 mb-20">Equipe de prospection commerciale </span>
                                <br /><br />
                                Vous avez des difficultés à trouver de nouveaux clients pour votre entreprise ? Contactez-nous sans tarder. ADIVE dispose d’une équipe de prospection dynamique, formée et expérimentée en matière de prospection commerciale pour vous aider à atteindre vos objectifs de prospections.
                                <br /><br />
                                <span className="h4 mb-20">Equipe de vente sur le terrain </span>
                                <br /><br />
                                Si vous êtes à la recherche de commerciaux terrains pour la vente de vos produits et services, contactez ADIVE. Une équipe de vente, prête pour le terrain est disponible pour vous aider à augmenter vos chiffres de vente.
                                <br /><br />
                                <span className="h4 mb-20">Equipe d’animatrice d’activité commerciale  </span>
                                <br /><br />
                                Vous organisez régulièrement des activités commerciales et vous désirer avoir une équipe dynamique pour jouer le rôle d’ambassadrice, n’allez pas chercher loin, ADIVE est a déjà fait le travail pour vous. Nous animateurs et animatrices sont toujours disponibles pour porter haut votre marque et l’amener loin auprès de vos cibles à travers leurs techniques de communication professionnelle et efficaces.
                                <br /><br />
                                <span className="h4 mb-20">Equipe de collecte de données </span>
                                La collecte des données sur le terrain nécessite d’avoir une équipe compétente, qui maitrise les outils et techniques de collectes de données et expérimentée dans la collecte de données dans toutes les régions du Burkina Faso. ADIVE dispose en son sein, une base de données disponible, d’équipe de collecte de données en tout lieu et en tout temps.

                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default ProspectionPage;