import React, { useState } from "react";
import CountUp from 'react-countup';
import config from "../config";
import ServicesHome from "../components/ServicesHome";
import ActualitesHome from "../components/ActualitesHome";
import Slideshow from "../components/HomePage/Slideshow";
import Testimonials from "../components/HomePage/Testimonials";
import ModalVideo from 'react-modal-video'
import VideoSection from "../components/VideoSection";

export default function HomePage(props) {
  const [activeTab, setActiveTab] = useState('tab1');
  const [isOpen, setOpen] = useState(false);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <Slideshow />

      <section className="">
        <div className="row gx-0 justify-content-center">
          <div class="my-50 title-area text-center">
            <span class="sub-title">Nos Valeurs</span>
          </div>
          <div className="col-xxl-4 col-md-8 feature-card-wrap">
            <div className="feature-card" data-bg-src="assets/img/bg/feature_card_bg.jpg">
              <div className="feature-card_icon"><img src="assets/img/icon/feature_1_1.svg" alt="icon" /></div>
              <h3 className="feature-card_title">Esprit de Créativité</h3>
              <p className="feature-card_text">L'esprit de créativité est au cœur de notre académie. Nous embrassons l'audace,
                l'innovation et la pensée imaginative pour repousser les limites de ce qui est possible.</p>
            </div>
          </div>
          <div className="col-xxl-4 col-md-8 feature-card-wrap">
            <div className="feature-card" data-bg-src="assets/img/bg/feature_card_bg.jpg">
              <div className="feature-card_icon"><img src="assets/img/icon/feature_1_2.svg" alt="icon" /></div>
              <h3 className="feature-card_title">Esprit d'Innovation</h3>
              <p className="feature-card_text">Nous sommes constamment à la recherche de nouvelles idées, de solutions novatrices
                et de technologies émergentes pour rester en tête de la vague numérique.</p>
            </div>
          </div>
          <div className="col-xxl-4 col-md-8 feature-card-wrap">
            <div className="feature-card" data-bg-src="assets/img/bg/feature_card_bg.jpg">
              <div className="feature-card_icon"><img src="assets/img/icon/feature_1_3.svg" alt="icon" /></div>
              <h3 className="feature-card_title">Esprit de Professionnalisme</h3>
              <p className="feature-card_text">Notre esprit de professionnalisme est la clé de notre succès. Nous nous engageons à fournir
                un service de haute qualité, à respecter les délais et à atteindre les objectifs fixés.</p>
            </div>
          </div>
        </div>
      </section>


      <div className="space bg-top-right" style={{ backgroundImage: "url(/assets/img/bg/about_bg_2.png)" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 mb-xl-0
                  mb-40">
              <div className="">
                {/* <div className="img1"><img src="/assets/img/normal/about_4_1.png" alt="About" /></div>
                <div className="img2"><img src="/assets/img/normal/about_4_2.png" alt="About" /></div> */}
                <div className="shape1"><img src="https://static.vecteezy.com/ti/vecteur-libre/p1/4566324-femme-d-affaires-portant-costumes-noirs-en-action-pose-caucasien-asiatique-et-noir-illustration-femme-d-entreprise-vectoriel.jpg" alt="About" /></div>
                {/* <div className="shape2"><img src="/assets/img/normal/about_shape_4_2.png" alt="About" /></div> */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-10">
              <div className="ps-xl-4 ms-xl-4">
                <div className="title-area mb-30"><span className="sub-title2"><img src="assets/img/shape/subtitle.svg" alt="Icon" /> A Propos De Nous</span>
                  <h2 className="sec-title">Nous sommes une académie numérique</h2>
                </div>
                <p className="mt-n2 mb-4">Nous sommes une entreprise spécialisée dans la fourniture de services liés à la formation, à la transformation digitale, à l’emploi, à la vente en ligne, à l’optimisation des stratégies de marketing digital et de communication digitale.</p>
                <div className="list-column2">
                  <div className="ticklist">
                    <ul>
                      <li>Formations diversifiées aux particuliers et entreprises</li>
                      <li>Digitalisation des entreprises</li>
                    </ul>
                  </div>
                  <div className="ticklist">
                    <ul>
                      <li>Vente en ligne</li>
                      <li>Gestion digitale des Emplois</li>
                    </ul>
                  </div>
                </div>
                <div className="about-grid-wrap">
                  <div className="about-grid">
                    <h3 className="about-grid_year">+<CountUp end={5} duration={10} /></h3><span className="about-grid_text">ans<br />d'expériences</span>
                  </div>
                  <div className="about-grid">
                    <div className="about-grid_icon">
                      <div className="gr-icon"><img src="assets/img/icon/message.svg" alt="icon" /></div>
                    </div>
                    <div className="media-body">
                      <h4 className="about-grid_title">Vous avez des questions ? Cliquez sur le lien ci-dessous</h4><span className="about-grid_link"><i className="far fa-hand-point-right me-2" />
                        <a href="/faq" className="gr-link">FAQ’S</a></span>
                    </div>
                  </div>
                </div><a href="/a-propos-de-nous" className="as-btn gr-btn">Savoir Plus</a>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <section className="simple " style={{ backgroundImage: "url(/assets/img/bg/cta_bg_5.jpg)" }}>
        <div className="container">
          <div className="row gy-40 align-items-center
              justify-content-between">
            <div className="col-lg-12">
              <h2 className="sec-title text-white mb-10">Besoin de notre expertise ? {config.firstContact}</h2>
              <p className="text-white mb-n2">N'hésitez pas à entrer en contact avec nous. </p>
            </div>
            <div className="col-lg-auto"><a href={`tel:${config.firstContact}`} className="call-btn">
              <div className="play-btn style4"><i className="fas fa-phone" /></div>
              <div className="btn-content"><span className="btn-text text-white">Contactez-Nous  </span> <span className="box-title gr-text">{config.firstContact}</span>
              </div>
            </a></div>
            <div className="col-lg-auto"><a href="/contact" className="as-btn gr-btn">Contact</a></div>
          </div>
        </div>
      </section> */}


      <div className="space">
        <div className="container z-index-common">
          <div className="row gy-40 justify-content-between">
            <div className="col-sm-6 col-xl-auto">
              <div className="counter-card">
                <div className="counter-card_icon style2"><i className="fa-thin fa-file-check" /></div>
                <div className="media-body">
                  <h2 className="counter-card_number text-title">+<CountUp end={100} duration={5} /></h2>
                  <p className="counter-card_text text-body">Partenaires Actifs</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-auto">
              <div className="counter-card">
                <div className="counter-card_icon style2"><i className="fa-thin fa-shield-check" /></div>
                <div className="media-body">
                  <h2 className="counter-card_number text-title">+<CountUp end={500} duration={5} /></h2>
                  <p className="counter-card_text text-body">Clients Actifs</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-auto">
              <div className="counter-card">
                <div className="counter-card_icon style2"><i className="fa-thin fa-mug-hot" /></div>
                <div className="media-body">
                  <h2 className="counter-card_number text-title">+<CountUp end={95} duration={5} />%</h2>
                  <p className="counter-card_text text-body">Clients Satisfaits</p>
                </div>
              </div>
            </div>
             <div className="col-sm-6 col-xl-auto">
              <div className="counter-card">
                <div className="counter-card_icon style2"><i className="fa-thin fa-face-smile" /></div>
                <div className="media-body">
                  <h2 className="counter-card_number text-title">+<CountUp end={900} duration={5} /></h2>
                  <p className="counter-card_text text-body">Témoignages</p>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>


      <div>
        <VideoSection titre="Présentation de notre académie" lien="../../assets/video.mp4" />
      </div>

      <ServicesHome />

      <ActualitesHome />

      <Testimonials />

    </>
  );
}
