import React, { useState } from "react";
import config from "../config";
import HeaderTop from "./HeaderTop";
import MenuItems from "./MenuItems.js";
import Search from './Search';
import LanguageSwitcher from "./LanguageSwitcher";

const Header = ({ onMenuButtonClick }) => {

    const [mobileMenu, setMobileMenu] = useState(false);

    const langues = [
        {
            name: "Anglais",
            value: "en"
        },
        {
            name: "Français",
            value: "fr"
        }, {
            name: "Arabe",
            value: "ar"
        }
    ];

    const menu = [
        {
            name: "Accueil",
            route: "/accueil",
            hasChildren: false
        },
        {
            name: "A Propos",
            route: "/a-propos-de-nous",
            hasChildren: false,
        },
        {
            name: "Services",
            route: "/nos-services",
            hasChildren: true,
            children: [
                {
                    name: "Formations professionnelles",
                    route: "/services/formations-profesionnelles",
                },
                {
                    name: "Conseils et Etudes de marché ",
                    route: "/services/conseils",
                },
                {
                    name: "Transformation digitale",
                    route: "/services/transformation-digitale",
                },
                {
                    name: "Mise en relation emploi",
                    route: "/services/mise-en-relation",
                }, {
                    name: "Service de livraison",
                    route: "/services/livraison",
                },
                {
                    name: "Mise à disposition d’équipes",
                    route: "/services/prospection",
                },/*  {
                    name: "Stratégie de marketing digital",
                    route: "/services/strategie-marketing-digitale",
                }, {
                    name: "Stratégie de Communication digitale",
                    route: "/services/strategie-marketing-digitale",
                },
                {
                    name: "Conception graphique & contenu web",
                    route: "/services/conception-graphique",
                } */
                // , {
                //     name: "Espace vente en ligne",
                //     route: "/adive-biblio"
                // }, {
                //     name: "Actualités",
                //     route: "/nos-actualites",
                //     hasChildren: false
                // },
            ]
        }, {
            name: "Nos formations",
            route: "/nos-formations",
            hasChildren: false
        }, {
            name: "Digital",
            route: "#",
            hasChildren: true,
            children: [
                {
                    name: "Stratégie de marketing digital",
                    route: "/digital/strategie-marketing-digital",
                },
                {
                    name: "Stratégie de communication digitale",
                    route: "/digital/strategie-communication-digitale",
                },
                {
                    name: "Conception graphique & contenu web",
                    route: "/digital/conception-graphique",
                },
                {
                    name: "Développement d'applications mobiles & logiciels",
                    route: "/digital/developpement-applicatif",
                }, {
                    name: "La Data et l'Analyse",
                    route: "/digital/data-analyse",
                }
            ]
        }, {
            name: "Emplois",
            route: "/nos-offres-emplois",
            hasChildren: false
        }, {
            name: "Devis",
            route: "/demander-un-devis",
            hasChildren: false
        }, {
            name: "Contact",
            route: "/contact",
            hasChildren: false
        }
    ];

    const additionalMenu = [
        {
            name: "Actualités",
            route: "/nos-actualites",
            hasChildren: false
        },
        {
            name: "Espace vente en ligne",
            route: "/adive-biblio"
        },
        {
            name: "FAQ",
            route: "/faq",
            hasChildren: false
        }
    ];

    const insertIndex = 6;

    // const sideMenu = [...menu, ...additionalMenu];
    const sideMenu = [...menu.slice(0, insertIndex), ...additionalMenu, ...menu.slice(insertIndex)];
    const toggleMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    };

    const closeMobileMenu = () => {
        setMobileMenu(false);
    };;

    return (
        <>
            <div className={`as-menu-wrapper ${mobileMenu ? 'as-body-visible' : ''}`}>
                <div className="as-menu-area text-center">
                    <button className="as-menu-toggle" onClick={closeMobileMenu}>
                        <i className="fal fa-times" />
                    </button>
                    <div className="mobile-logo"><a href="/"><img src="/assets/img/adiveLogo.png" alt="Adive logo" className="logo" width={50} /></a></div>
                    <div className="as-mobile-menu">
                        <MenuItems menu={sideMenu} />
                    </div>
                </div>
            </div>
            <header className="as-header header-layout4" style={{ position: "fixed", zIndex: 999 }}>
                {config.showTopHeader && <HeaderTop text="Bienvenue sur la plateforme du DIGITAL & de la VENTE" />}
                <div className="menu-top">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-auto">
                                <div className="header-logo"><a href="/"><img src="/assets/img/adiveLogo.png" alt="Adive" width={80} /><span className="notranslate" style={{ fontSize: 30, fontWeight: 'bold' }}>ADIVE</span></a>
                                </div>
                            </div>
                            <div className="col-auto d-none d-lg-inline-block">
                                <div className="header-info-wrap">
                                    <div className="header-info">
                                        <div className="header-info_icon icon-btn"><i className="fa-solid fa-phone" /></div>
                                        <div className="media-body"><span className="header-info_text">Appel</span>
                                            <a className="header-info_link" href={`tel:${config.firstContact}`}>{config.firstContact}</a>
                                            <a href="https://wa.me/71153848" class="whatsapp-button" target="_blank">
                                                <img src="https://cdn-icons-png.flaticon.com/512/174/174879.png" alt="WhatsApp" width="30" height="30" style={{ marginTop: -10, marginLeft: 10 }} />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="header-info">
                                        <div className="header-info_icon icon-btn"><i className="fa-solid fa-envelope" /></div>
                                        <div className="media-body"><span className="header-info_text">Email</span>
                                            <a className="header-info_link" href={`mailto:${config.email}`}>{config.email}</a>
                                        </div>
                                    </div>
                                    <div className="header-info">
                                        <div className="header-info_icon icon-btn"><i className="fa-solid fa-clock" /></div>
                                        <div className="media-body"><span className="header-info_text">Disponibilité</span>
                                            <p className="header-info_link">{config.availability}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LanguageSwitcher titre="Langue" langues={langues} />
                            <div className="col-auto">
                                {/* <a href="/contact" className="as-btn gr-btn d-lg-inline-block d-none">Contactez-Nous</a> */}
                                <button onClick={toggleMobileMenu} type="button" className="as-menu-toggle d-inline-block d-lg-none">
                                    <i className="far fa-bars" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="logo-bg" />
                </div>
                <div className="sticky-wrapper">
                    <div className="sticky">
                        <div className="container" style={{ width: "fit-content" }}>
                            <div className="menu-area">
                                {/* <div className="row align-items-center justify-content-between"> */}
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <nav className="main-menu d-none d-lg-inline-block">
                                            <MenuItems menu={menu} />
                                        </nav>
                                    </div>
                                    <div className="col-auto d-none d-lg-block">
                                        <div className="header-button">
                                            <Search />
                                            {/* <a href="/mon-panier" className="icon-btn">
                                                <i className="far fa-shopping-cart" />
                                            </a> */}
                                            <a href="/login" className="icon-btn">
                                                <i className="far fa-user" />
                                            </a>

                                            <button className="icon-btn sideMenuToggler" onClick={onMenuButtonClick}>
                                                <i className="far fa-bars" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header >
        </>
    );
}

export default Header;
