import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchTrainings } from '../../redux/actions/trainingsActions';
import Training from '../../components/Training';
import Skelet from '../../components/Skeleton';
import Breadcumb from '../../components/Breadcumb';
import SortBy from '../../components/TrainingsSidebar/SortBy';
import Categories from '../../components/TrainingsSidebar/Categories';
import Level from '../../components/TrainingsSidebar/Level';
import Price from '../../components/TrainingsSidebar/Price';
import Searchbar from '../../components/Searchbar';

const TrainingsPage = (props) => {
    const { loading, trainings, fetchTrainings } = props;
    useEffect(() => {
        fetchTrainings();
    }, [fetchTrainings]);

    const breadcumb = {
        text: "Toutes nos formations",
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "Nos formations"
    }

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    return (
        <>

            <div >
                {/* <Breadcumb breadcumb={breadcumb} /> */}
                <div className="banner-Search-Back">
                    <section className=" space" style={{ marginTop: 50 }}>
                        <div className="">
                            <div className="banner-style5__content">
                                <div className="slider-bg-slide-overly" style={{backgroundColor: 'gray'}}/>
                                <div className="banner-style5__content-inner">
                                    <div className="big-title">
                                        <h2>
                                            Toutes nos formations
                                            <br /> Disponibles pour tous !!!.
                                        </h2>
                                    </div>
                                    <div className="highlights-box">
                                        <ul>
                                            <li>
                                                {/* <span className="fa-solid fa-check" /> */}
                                                <h4>Approche Personnalisée </h4>
                                            </li>
                                            <li>
                                                {/* <span className="fa-solid fa-check" /> */}
                                                <h4> Adaptées à vos Besoins</h4>
                                            </li>
                                            <li>
                                                {/* <span className="fa-solid fa-check" /> */}
                                                <h4>Depuis le confort de votre domicile</h4>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <div className="banner-style5-search-box">
                                    <form className="search-form" action="#">
                                        <input
                                            placeholder="Find & choose your perfect course"
                                            type="text"
                                        />
                                        <button type="submit">
                                            <i className="icon-zoom" />
                                        </button>
                                    </form>
                                </div> */}
                                    {/*Start Banner Style5 Features Box Single */}
                                    <div className="banner-style5-features-box-single one">
                                        <div className="icon">
                                            <span className="fal fa-diploma" />
                                        </div>
                                        <h3>
                                            Formations
                                            <br /> Certifiantes
                                        </h3>
                                    </div>
                                    {/*End Banner Style5 Features Box Single */}
                                    {/*Start Banner Style5 Features Box Single */}
                                    <div className="banner-style5-features-box-single two">
                                        <div className="icon">
                                            <span className="fal fa-arrows-rotate" />
                                        </div>
                                        <h3>
                                            Formations
                                            <br /> Inter-Entreprises
                                        </h3>
                                    </div>
                                    {/*End Banner Style5 Features Box Single */}
                                    {/*Start Banner Style5 Features Box Single */}
                                    <div className="banner-style5-features-box-single three">
                                        <div className="icon">
                                            <span className="fal fa-square-list" />
                                        </div>
                                        <h3>
                                            Formation
                                            <br /> à la Carte
                                        </h3>
                                    </div>
                                    {/*End Banner Style5 Features Box Single */}
                                    {/*Start Banner Style5 Features Box Single */}
                                    <div className="banner-style5-features-box-single four">
                                        <div className="icon">
                                            <span className="fal fa-earth-africa" />
                                        </div>
                                        <h3>
                                            E-learning
                                            <br /> Classe virtuelle
                                        </h3>
                                    </div>
                                    {/*End Banner Style5 Features Box Single */}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <Searchbar options={options} /> */}

                </div>

                <section className="as-blog-wrapper blog-details mt-50 space-extra-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-lg-12">
                                <div className="row gy-4">
                                    {!loading && trainings.map((training) => (

                                        <Training
                                            title={training.attributes ? training.attributes.Theme : ''}
                                            publication={training.attributes ? training.attributes.publishedAt : ''}
                                            description={training.attributes ? training.attributes.description : ''}
                                            nombre_heures={training.attributes ? training.attributes.nombre_heures : ''}
                                            lieu={training.attributes ? training.attributes.lieu : ''}
                                            langue={training.attributes ? training.attributes.langue : ''}
                                            prix_formation={training.attributes ? training.attributes.prix_formation : ''}
                                            typesformation={training.attributes.typesformation ? training.attributes.typesformation.data.attributes.nom : ''}
                                            training={training}
                                            formateur={training.attributes.formateur ? training.attributes.formateur.data.attributes : {}}
                                            modesformation={training.attributes.modesformation ? training.attributes.modesformation : {}}
                                            modespayment={training.attributes.modespayment ? training.attributes.modespayment : {}}
                                        />
                                    ))}
                                    {loading && <Skelet />}
                                </div>
                                {/* <div className="text-center mt-5">
                                    <div className="as-pagination">
                                        <ul>
                                            <li><a href="#">1</a></li>
                                            <li><a href="#">2</a></li>
                                            <li><a href="#">3</a></li>
                                            <li><a href="#"><i className="far fa-arrow-right" /></a></li>
                                        </ul>
                                    </div>
                                </div> */}

                            </div>
                            {/* <div className="col-xxl-3 col-lg-3">
                                <aside className="sidebar-area">

                                    <SortBy titre="Trier par" />
                                    <Categories titre="Catégories" />
                                    <Level titre="Niveau" />
                                    <Price titre="Prix" />

                                </aside>
                            </div> */}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        trainings: state.trainings.trainings,
        loading: state.trainings.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTrainings: () => dispatch(fetchTrainings())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingsPage);