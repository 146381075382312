import React, { useState } from "react";
import { Row, Col, Modal } from 'antd';
import { createNewsLetter } from '../redux/actions/newsletterActions';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';

const SubscribeNewsLetter = (props) => {
    const dispatch = useDispatch();
    const { titre } = props;
    const [alert, setAlert] = useState(null);
    const [formData, setFormData] = useState({
        nom: '',
        email: ''
    });

    const [newsLetterLoading, setNewsLetterLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        setNewsLetterLoading(true);
        event.preventDefault();
        if (!validateEmail(formData.email)) {
            setAlert({
                type: 'error',
                message: 'Email Invalide!',
            });
            setNewsLetterLoading(false);
            return;
        } else closeAlert();

        let data = { "data": formData }
        const response = await dispatch(createNewsLetter(data));
        //console.log({ response })
        setAlert({
            type: response ? 'success' : 'error',
            message: response ? 'Merci d\'avoir souscrit à notre newsletter !' : 'Une Erreur s\'est produite veuillez réessayer !',
        });
        // Reset the form data after the API call (optional)
        setFormData({
            nom: '',
            email: '',
        });
        setNewsLetterLoading(false);
    };

    const closeAlert = () => {
        setAlert(null);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };


    return (
        <>
            {alert && (
                <div className={`alert text-center my-3 ${alert.type === 'success' ? 'alert-success' : 'alert-danger'}`} role="alert">
                    {alert.message}
                    <button type="button" className="btn-close" aria-label="Close" onClick={closeAlert}></button>
                </div>
            )}
            <div
                className="subscribe-sec background-image"
                style={{ backgroundImage: 'url("assets/img/bg/subscribe_bg_1.png")' }}
            >
                <div className="row align-items-center justify-content-between">
                    <div className="col-xl-7 col-lg mb-40 mb-lg-0">
                        <h5 className="text-white mb-2 mt-n2">
                            {titre}
                        </h5>
                    </div>
                    <div className="col-lg-auto">
                        <form className="newsletter-form style2" onSubmit={handleSubmit}>
                            <input
                                className="form-control"
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Votre e-mail"
                                required
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            <button
                                type="submit"
                                className="as-btn"
                                disabled={newsLetterLoading}> {newsLetterLoading ? 'Soucription en cours...' : 'Souscrire'}
                            </button>

                        </form>
                    </div>
                </div>
            </div>

        </>
    );
}

const mapStateToProps = (state) => {
    return {
        //newsletters: state.newsletters.newsletters,
        loading: state.devis.loading
    };
};

export default connect(mapStateToProps)(SubscribeNewsLetter);
