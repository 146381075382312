import React from "react";

export default function Slideshow(props) {

    return (
        <>
            <div className="as-hero-wrapper hero-4">
                <div className="hero-slider-4 as-carousel" data-fade="true" data-slide-show={1} data-md-slide-show={1}>
                    <div className="as-hero-slide">
                        <div className="as-hero-bg" data-bg-src="assets/img/home/hero/hero_bg_4_1.png" style={{ backgroundImage: 'url(assets/img/home/hero/bg.jpg)' }} />
                        <div className="container z-index-common">
                            <div className="hero-style4" style={{marginTop: 50}}><span style={{color: "white", fontWeight: "bold", fontSize: 25 }}>Bienvenue sur la plateforme ADIVE</span>
                                <h1 className="hero-title" data-ani="slideinleft" data-ani-delay="0.1s">Académie du Digital &amp;</h1>
                                <h1 className="hero-title" data-ani="slideinleft" data-ani-delay="0.2s">
                                    <span className="gr-text">de la Vente</span>
                                </h1>
                                <p className="hero-text" data-ani="slideinleft" data-ani-delay="0.3s">Votre destination de choix pour
                                    tout ce qui concerne la formation, le digital et l’emploi ! Nous sommes une académie
                                    passionnée et créative, prête à vous accompagner dans votre parcours professionnel et à
                                    vous aider à développer votre entreprise.</p>
                                <div className="btn-group" data-ani="slideinleft" data-ani-delay="0.4s"><a href="/nos-services" className="as-btn gr-btn">Nos
                                    Services</a> <a href="/contact" className="as-btn style2">Contactez-Nous</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
