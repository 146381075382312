import { useEffect, useState } from "react";
import { getCookie, hasCookie, setCookie } from 'cookies-next';
import config from "../config";

const LanguageSwitcher = ({ titre, langues }) => {
    useEffect(() => {
        // Add Google Translate script to the document
        const addGoogleTranslateScript = () => {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
            document.head.appendChild(script);
        };

        // Initialize Google Translate widget
        window.googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                { pageLanguage: 'fr' },
                'google_translate_element'
            );
        };

        // Check if script already exists
        if (!document.querySelector("script[src='https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit']")) {
            addGoogleTranslateScript();
        } else {
            window.googleTranslateElementInit();
        }

        // Apply the selected language from the cookie
        if (hasCookie('selectedLang')) {
            const selectedLang = getCookie('selectedLang');
            const googleTranslate = document.querySelector('.goog-te-combo');
            if (googleTranslate) {
                googleTranslate.value = selectedLang;
                googleTranslate.dispatchEvent(new Event('change'));
            }
        }
    }, []);

    const handleLanguageChange = (e) => {
        const langCode = e.target.value;
        const googleTranslate = document.querySelector('.goog-te-combo');
        if (googleTranslate) {
            googleTranslate.value = langCode;
            googleTranslate.dispatchEvent(new Event('change'));
        }
        setCookie('selectedLang', langCode);
    };

    return (
        <>
            {config.showLang && (
                <div className="d-lg-inline-block d-none col-auto LanguageSwitcher">
                    <select
                        className="selectpicker"
                        data-width="fit"
                        onChange={handleLanguageChange}
                        defaultValue={hasCookie('selectedLang') ? getCookie('selectedLang') : 'fr'}
                    >
                        {langues &&
                            langues.map((langue, i) => (
                                <option key={i} value={langue.value} data-content={`<span class="flag-icon flag-icon-${langue.code}"></span> ${langue.name}`}>
                                    {langue.name}
                                </option>
                            ))}
                    </select>
                    <div id="google_translate_element" style={{ display: 'none' }}></div>
                </div>
            )}
        </>
    );
};

export default LanguageSwitcher;