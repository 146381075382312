const Breadcumb = ({ breadcumb, marginBottom }) => {
    let mb = marginBottom ? marginBottom : '100';
    return (
        <>
            {
                breadcumb && (
                    <div className={'space mb-' + mb}>
                        {/* <div className="breadcumb-wrapper bg-gradient-11" style={{ backgroundImage: 'url("https://eduvibe.html.devsvibe.com/assets/images/bg/home-two-about-us.jpg")' }}></div> */}
                        {/* <div className="breadcumb-wrapper" style={{ backgroundImage: 'url("https://eduvibe.html.devsvibe.com/assets/images/bg/home-two-about-us.jpg")' }}> */}
                        <div className="breadcumb-wrapper" >
                            <div className="container">
                                < div className="breadcumb-content"
                                    style={{ marginTop: 80 }}
                                >
                                    <h1 className="breadcumb-title text-black" style={{ textTransform: "unset" }}>{breadcumb.text}</h1>
                                    <ul className="breadcumb-menu">
                                        <li><a href={breadcumb.mainLink}>{breadcumb.mainLinkText}</a></li>
                                        <li>{breadcumb.sublink}</li>
                                    </ul>
                                </div>
                            </div>
                        </div >
                    </div >
                )
            }

        </>
    );
}

export default Breadcumb;
