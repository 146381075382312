import React, { useEffect } from 'react';
import { fetchJobs } from '../../redux/actions/jobsActions';
import Services from "../../components/Services";
import Breadcumb from '../../components/Breadcumb';
import Pricing from '../../components/Pricing';
import DemandeEmploi from '../../components/DemandeEmploi';
import VideoSection from '../../components/VideoSection';
import CountUp from 'react-countup';

const LivraisonPage = (props) => {
    const breadcumb = {
        text: "Livraison",
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "Livraison"
    }

    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />
                <div className="space" id="about-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 pe-xl-5">
                                <div className="img-box1">
                                    <div className="group">
                                        <div className="img1">
                                            <img src="https://img.freepik.com/vecteurs-premium/livraison-domicile-toute-securite_37895-291.jpg" alt="About" />
                                        </div>
                                        <div className="img3">
                                            <img src="https://lynkbooster.com/wp-content/uploads/2021/04/LIV.jpg" alt="About" />
                                        </div>
                                    </div>
                                    <div className="shape1">
                                        <img src="../assets/img/shape/dot_1.png" alt="shape" />
                                    </div>
                                    <div className="shape2">
                                        <img src="../assets/img/shape/bg_shape_1.png" alt="shape" />
                                    </div>
                                    <div className="about-counter1">
                                        <h3 className="counter-title">
                                            <span className="counter-number">200</span>+
                                        </h3>
                                        <span className="counter-text">Livreurs</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="title-area mb-30">
                                    <span className="sub-title">Service de Livraison</span>
                                    <h2 className="sec-title">La livraison au bout des doigts</h2>
                                </div>
                                <p className="mt-n2 mb-4">
                                Nos services de livraison vous permettent d’économiser du temps et de l’énergie. Nous disposons d’une équipe dynamique et de la logistique nécessaire pour assurer votre besoin de livraison avec succès.
                                </p>
                                <p className="mt-n2 mb-4">
                                Les tarifs de livraison dépendent de la taille de la commande, du lieu de livraison, de la quantité ou du poids d’article à livrer. Vous pouvez décider ce qui vous convient, à vous et à votre budget.
                                </p>

                                <div className="btn-group">
                                    <a href="#" className="as-btn">
                                        Devenir Livreur
                                    </a>
                                    <a href="#" className="as-btn">
                                        Demander une Livraison
                                    </a>
                                    {/* <a href="about.html" className="as-btn style7">
                                        More Details
                                    </a> */}
                                </div>
                                <p className="space"><b>Voir nos tarifs</b> :  <br/>Ouagadougou : A partir de 1000 FCFA <br/>Hors Ouagadougou : A partir de 2000 FCFA</p>
                            </div>
                            <div className="space">
                                <div className="container z-index-common">
                                    <div className="row gy-40 justify-content-between">
                                        <div className="col-sm-6 col-xl-auto">
                                            <div className="counter-card">
                                                <div className="counter-card_icon style2"><i className="fa-thin fa-file-check" /></div>
                                                <div className="media-body">
                                                    <h2 className="counter-card_number text-title">+<CountUp end={100} duration={5} /></h2>
                                                    <p className="counter-card_text text-body">Partenaires Actifs</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xl-auto">
                                            <div className="counter-card">
                                                <div className="counter-card_icon style2"><i className="fa-thin fa-shield-check" /></div>
                                                <div className="media-body">
                                                    <h2 className="counter-card_number text-title">+<CountUp end={500} duration={5} /></h2>
                                                    <p className="counter-card_text text-body">Clients Actifs</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xl-auto">
                                            <div className="counter-card">
                                                <div className="counter-card_icon style2"><i className="fa-thin fa-mug-hot" /></div>
                                                <div className="media-body">
                                                    <h2 className="counter-card_number text-title">+<CountUp end={95} duration={5} />%</h2>
                                                    <p className="counter-card_text text-body">Clients Satisfaits</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xl-auto">
                                            <div className="counter-card">
                                                <div className="counter-card_icon style2"><i className="fa-thin fa-face-smile" /></div>
                                                <div className="media-body">
                                                    <h2 className="counter-card_number text-title">+<CountUp end={900} duration={5} /></h2>
                                                    <p className="counter-card_text text-body">Témoignages</p>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>

                            <img src="https://cdn.shopify.com/s/files/1/0249/6376/files/livraison-locale-shopify.jpg?v=1609928123" />
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default LivraisonPage;