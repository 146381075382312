export default {
  appName: "ADIVE",
  showTopHeader: false,
  showLang: true,
  showSearch: false,
  email: "contact@adive.net",
  contact: "(+226) 69-09-09-37 / 66-09-09-37 / 71-15-38-48",
  firstContact: "(+226) 69-09-09-37",
  secondContact: "(+226) 66-09-09-37",
  thirdContact: "(+226) 71-15-38-48",
  facebook: "Adive",
  instagram: "Adive",
  linkedIn: "Adive",
  twitter: "Adive",
  web: "www.adive.net",
  apiUrl: 'https://adive-backend-api.warilo.com/api',
  //apiUrl: 'http://localhost:1337/api',
  availability: "Lun - Ven : 08H - 18H | Sam : 08H - 13H"
};