import React, { useState } from "react";
import { Row, Col, Modal } from 'antd';
import { CloseCircleFilled, EyeOutlined, ShoppingCartOutlined, CaretUpFilled, CaretDownFilled } from "@ant-design/icons";


const BiblioCard = (props) => {
    let biblio = props.biblio.attributes
    const [selectedBiblio, setSelectedBiblio] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const maxItems = 10;

    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const increaseQuantity = () => {
        if (quantity < maxItems) {
            setQuantity(quantity + 1);
        }
    };

    const openModal = (biblio) => {
        setSelectedBiblio(biblio);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedBiblio(null);
        setIsModalOpen(false);
    };
    return (
        <>
            <div
                className="col-lg-3 col-md-6 col-sm-6 col-12"
                data-sal-delay={150}
                data-sal="slide-up"
                data-sal-duration={800}
            >
                <div className="BiblioCard as-team team-grid  eduvibe-shop-single-product">
                    <div className="inner">
                        <div className="eduvibe-single-product-thumb-wrapper">
                            <a style={{ cursor: "pointer" }} onClick={() => openModal(props)}>
                                <img src={biblio.image ? biblio.image.data.attributes.url : ''} alt="Image" style={{height: 300}} />
                            </a>
                            <div className="product-hover-info mx-3">
                                <ul>
                                    <li>
                                        <a style={{ cursor: "pointer" }} className="secondary-button" onClick={() => openModal(props)}> <EyeOutlined className="mx-2" />Voir Plus</a>
                                    </li>
                                    <li>
                                        <a style={{ cursor: "pointer" }} className="primary-button" href="#"> <ShoppingCartOutlined className="mx-2" />Panier</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="content">
                            <span className="subtitle">Par: {biblio.auteur}</span>
                            <h6 className="title">
                                <a style={{ cursor: "pointer" }} onClick={() => openModal(biblio)}>
                                    {biblio.titre.slice(0,20)}...
                                </a>
                            </h6>
                            <div className="price-list price-style-03">
                                <div className="price current-price">{biblio.prix} CFA</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                title=""
                centered
                open={isModalOpen}
                footer={null}
                onCancel={closeModal}
                width={800}
                closeIcon={
                    <CloseCircleFilled
                        style={{ fontSize: '30px', color: '#60686B' }}
                        theme="outlined"
                    />}
            ><section>
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-lg-12">
                                <div className="edu-modal-body">
                                    <div className="row g-5 row--15">
                                        <div className="col-lg-5">
                                            <div className="thumbnail">
                                                <img
                                                    src={biblio.image ? biblio.image.data.attributes.url : ''}
                                                    alt="Image"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="biblio-content">
                                                <span className="subtitle">Par: {biblio.auteur}</span>
                                                <h4 className="title"> {biblio.titre}</h4>
                                                <div className="price-list price-style-03">
                                                    <div className="price current-price">{biblio.prix} CFA</div>
                                                </div>
                                                <p className="biblio-description">
                                                    {biblio.description}
                                                </p>
                                                <div className="product-action">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="edu-quontity-btn">
                                                                <div className="pro-qty">
                                                                    <span className="dec qtybtn" onClick={decreaseQuantity}>
                                                                        <CaretDownFilled />
                                                                    </span>
                                                                    <input type="text" value={quantity} readOnly />
                                                                    <span className="inc qtybtn" onClick={increaseQuantity}>
                                                                        <CaretUpFilled />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="add-to-cart-btn">
                                                                <a className="edu-btn" href="#">
                                                                    Acheter
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </Modal>
        </>
    );
}


export default BiblioCard;
