import React, { useEffect } from 'react';
import { fetchJobs } from '../../redux/actions/jobsActions';
import Services from "../../components/Services";
import Breadcumb from '../../components/Breadcumb';
import Pricing from '../../components/Pricing';
import DemandeEmploi from '../../components/DemandeEmploi';
import VideoSection from '../../components/VideoSection';

const DeveloppementAppicatifPage = (props) => {
    const breadcumb = {
        text: "Développement d'applications mobiles & logiciels",
        mainLinkText: 'Accueil',
        mainLink: '/accueil',
        sublink: "Développement d'applications mobiles & logiciels"
    }

    return (
        <>
            <div>
                <Breadcumb breadcumb={breadcumb} />
                <div className="overflow-hidden space">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 pe-xxl-12">
                            <img src="https://fnh.ma//uploads/actualites/6107de73cfd03_photo%20papier%20tech.jpg" alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-lg-12">
                                <div className="page-single">
                                    <div className="page-content">
                                        <h2 className="h3 page-title">Le développement d'applications mobiles </h2>
                                        <h4 className="h4"></h4>
                                        <p> Avec ce service, vous pouvez offrir une multitude de services à vos clients sur smartphones, avec les systèmes iOS et Android dominants. L'e-commerce connaît une croissance notable, offrant diverses options de vente en ligne telles que le dropshipping ou les marketplaces.</p>
                                   
                                        <h2 className="h3 page-title">Le développement de logiciels </h2>
                                        <h4 className="h4"></h4>
                                        <p> Le développement logiciel est un de nos service qui vous permet de créer des solutions sur mesure vous permettant de gérer efficacement vos différentes activités afin de viser l’excellence à travers une gestion efficiente.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </section >
            </div>
        </>
    );
}

export default DeveloppementAppicatifPage;