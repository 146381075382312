import axios from 'axios';
import config from '../../config';

export const fetchNewsletterSuccess = (newsletters) => {
  return {
    type: 'FETCH_NEWSLETTER_SUCCESS',
    payload: newsletters
  };
};

export const createNewsletterSuccess = (newNewsletters) => {
  return {
    type: 'CREATE_NEWSLETTER_SUCCESS',
    payload: newNewsletters
  };
};

export const createNewsLetter = (newslettersData) => async (dispatch) => {
  try {
    const url = config.apiUrl + '/newsletters';
    const response = await axios.post(url, newslettersData);
    if (response.status === 200) {
      dispatch(createNewsletterSuccess(response.data.data));
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('createNewsletter failed:', error);
    return false;
  }
};

export const fetchDevis = () => {
  return (dispatch) => {
    axios.get(config.apiUrl + "/newsletters?populate=*")
      .then((response) => {
        //console.log(response.data.data)
        dispatch(fetchNewsletterSuccess(response.data.data));
      })
      .catch((error) => {
        // Gérer les erreurs ici
        console.error(error);
      });
  };
};